import type { RouteComponent } from 'vue-router';

import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';

export function getSportlineNavigationRouteComponent(): (() => Promise<RouteComponent>) | undefined {
  const { isBetSwipeNavigationLayoutEnabled } = useSportlineLayoutSettings();

  if (process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_ENABLED && isBetSwipeNavigationLayoutEnabled.value) {
    return () => import('./views/NavigationSportEventsRouteComponent/NavigationBySportsRouteComponent.vue');
  }

  if (!process.env.VUE_APP_LAYOUT_PHONE) {
    return undefined;
  }

  return () => import('./views/NavigationSportEventsRouteComponent/NavigationSportEventsRouteComponent.vue');
}

export function getSportlineDetailsPageNavigationRouteComponent(): (() => Promise<RouteComponent>) | undefined {
  const { isBetSwipeNavigationLayoutEnabled } = useSportlineLayoutSettings();

  if (process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_ENABLED && isBetSwipeNavigationLayoutEnabled.value) {
    return () => import('./views/NavigationSportEventsRouteComponent/NavigationBySportsRouteComponent.vue');
  }

  return undefined;
}

export function getSportlineNavigationLoaderRouteComponent(): (() => Promise<RouteComponent>) | undefined {
  return () => import('./views/NavigationMainContentLoaderRouteComponent/NavigationMainContentLoaderRouteComponent.vue');
}
