import { CasinoRouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class CasinoLoyaltyProgramModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addProfileRoute({
      name: CasinoRouteName.CASINO_LOYALTY_PROGRAM,
      path: '/profile/casino-loyalty-program',
      component: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? () => import('web/src/modules/casino-loyalty-program/pages/CasinoLoyaltyProgramRoutePage/SCasinoLoyaltyProgramRoutePage.vue')
        : () => import('web/src/modules/casino-loyalty-program/pages/CasinoLoyaltyProgramRoutePage/CasinoLoyaltyProgramRoutePage.vue'),
      props: true,
      topBar: () => import('web/src/modules/casino-loyalty-program/components/LoyaltyProgramTopBarRouteComponent/LoyaltyProgramTopBarRouteComponent.vue'),
      title: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? '{{$t(\'WEB2_LOYALTY_PROGRAM_PAGE_TITLE\')}}'
        : '{{$t(\'WEB2_LOYALTY_PROGRAM_CASINO_LEVEL\')}}',
      prefetch: () => import('web/src/modules/casino-loyalty-program/prefetch/CasinoLoyaltyProgramPagePrefetch'),
    });

    router.addProfileRoute({
      name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_ID,
      path: '/profile/casino-loyalty-program/:id',
      component: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? () => import('web/src/modules/casino-loyalty-program/pages/CasinoLoyaltyProgramRoutePage/SCasinoLoyaltyProgramRoutePage.vue')
        : () => import('web/src/modules/casino-loyalty-program/pages/CasinoLoyaltyProgramRoutePage/CasinoLoyaltyProgramRoutePage.vue'),
      props: true,
      topBar: () => import('web/src/modules/casino-loyalty-program/components/LoyaltyProgramTopBarRouteComponent/LoyaltyProgramTopBarRouteComponent.vue'),
      title: '{{$t(\'WEB2_LOYALTY_PROGRAM_CASINO_LEVEL\')}}',
    });

    router.addProfileRoute({
      name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_INFO,
      path: '/profile/casino-loyalty-program-info',
      component: () => import('web/src/modules/casino-loyalty-program/pages/CasinoLoyaltyProgramInfoRoutePage/CasinoLoyaltyProgramInfoRoutePage.vue'),
      title: '{{$t(\'WEB2_LOYALTY_PROGRAM_CASINO_INFO\')}}',
    });
  }
}
