import type { App } from 'vue';
import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';

import { EnvironmentPlugin } from '@leon-hub/plugins';

import { I18nPlugin } from '@core/i18n';
import { AnalyticsPlugin } from '@core/metrics';
import { useQueryManager } from '@core/query-manager';
import { createStoreThemeGetter, ThemePlugin } from '@core/theme';

import { vCordovaDocumentLink } from 'web/src/modules/cordova/directives';
import { CopyToClipboardPlugin } from 'web/src/modules/dialogs/plugins';
import CmsUtilsPlugin from 'web/src/plugins/CmsUtilsPlugin';
import RouteNamePlugin from 'web/src/plugins/RouteNamePlugin';
import autoResize from 'web/src/utils/directives/autoResize';
import { dataTest } from 'web/src/utils/directives/dataTest';

function applyBootstrapUrlConfiguration(app: App): void {
  const queryManager = useQueryManager();

  // Enable vue dev-tools performance tab.
  app.config.performance = Boolean(queryManager.getParameter('vue-performance', true));
}

export function configureVueAppEssentials(app: App): App {
  app.config.compilerOptions.whitespace = 'condense';
  app.config.compilerOptions.isCustomElement = (tag) => ['noindex'].includes(tag);

  if (!process.env.VUE_APP_BUNDLER_NUXT) {
    // Setup pinia store first
    const pinia = createPinia();
    app.use(pinia);
  }

  app.use(EnvironmentPlugin);

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    app.directive('cordova-document-link', vCordovaDocumentLink);
  }

  applyBootstrapUrlConfiguration(app);

  app.directive('data-test', dataTest);
  app.directive('auto-resize', autoResize);

  if (!process.env.VUE_APP_BUNDLER_NUXT) {
    app.use(createHead());
  }
  app.use(I18nPlugin);
  app.use(RouteNamePlugin);
  app.use(CopyToClipboardPlugin);
  app.use(CmsUtilsPlugin);
  app.use(ThemePlugin, createStoreThemeGetter());
  app.use(AnalyticsPlugin);

  return app;
}
