import type { Plugin } from 'vue';

import type { AppModule } from '@core/modularization';
import type { AppVueRouter } from '@core/router';

export const getAppModulePlugin: () => Plugin<{
  router: AppVueRouter;
  modules: AppModule[];
}> = () => ({
  install(app, {
    router,
    modules,
  }) {
    for (const module of modules) {
      module.install({ router });
    }
  },
});
