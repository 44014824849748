import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

export class TFAModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.TWO_FACTOR_AUTHENTICATION_LOGIN,
      path: '/login/two-factor-authentication',
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      title: '{{$t(\'JS_MOBILE_SETTINGS_TFAUTH_TITLE\')}}',
      component: () => import('./pages/TfaLoginRoutePage/TfaLoginRoutePage.vue'),
      access: CustomerRouteAccessRole.ANONYMOUS,
    });

    router.addProfileRoute({
      name: RouteName.TWO_FACTOR_AUTHENTICATION,
      path: '/profile/settings/two-factor-authentication',
      prefetch: () => import('./prefetch/TfaPagePrefetch'),
      component: () => import('./pages/TfaRoutePage/TfaRoutePage.vue'),
      topBar: () => import('./layouts/TfaRoutePageTopBar.vue'),
      title: '{{$t(\'JS_MOBILE_SETTINGS_TFAUTH_TITLE\')}}',
    });

    router.addProfileRoute({
      name: RouteName.TWO_FACTOR_AUTHENTICATION_REGENERATE,
      path: '/profile/settings/two-factor-authentication/regenerate',
      prefetch: () => import('./prefetch/TfaPagePrefetch'),
      component: () => import('./pages/TfaRegenerateRoutePage/TfaRegenerateRoutePage.vue'),
      title: '{{$t(\'WEB2_G2SV_REGENERATE_TITLE\')}}',
    });
  }
}
