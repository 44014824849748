import {
  getLocationPath,
  getLocationSearch,
} from '@leon-hub/service-locator-env';

import {
  getNewUri,
  prepareRedirectUri,
} from '@core/router';

import type { LanguagePrefixNavigationGuardOptions } from './types';

export default function checkPrefix(currentRedirectUri: string, {
  currentUrlPrefix,
  replacedUrlPrefix,
}: LanguagePrefixNavigationGuardOptions): string {
  let newRedirectUri = currentRedirectUri;

  if (currentUrlPrefix !== replacedUrlPrefix) {
    const pathname = getLocationPath();
    const search = getLocationSearch();
    const uri = `${pathname}${search}`;
    const newUri = getNewUri(replacedUrlPrefix, currentUrlPrefix);

    if (newUri !== uri) {
      newRedirectUri = prepareRedirectUri(newUri);

      if (process.env.VUE_APP_RENDERING_CSR && !process.env.VUE_APP_PRERENDER) {
        window.history.replaceState(
          null,
          '',
          newUri,
        );
      }
    }
  }

  return newRedirectUri;
}
