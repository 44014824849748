import type { Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import {
  computed,
  ref,
  toRef,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useLocalStorageManager } from '@leon-hub/local-storage';
import { RouteName } from '@leon-hub/routing-config-names';

import { useAnalytics } from '@core/analytics';
import { useCustomerDataStore } from '@core/customer';
import { useCustomerFinanceStore } from '@core/money';
import { useSiteConfigStore } from '@core/site-config';

import { useBonusStore } from 'web/src/modules/bonuses/store';
import { isCasinoGameRouteName } from 'web/src/modules/casino/utils';
import { usePaymentsDepositsNavigation } from 'web/src/modules/core/composables/navigation';
import useFomoBonusNotification
  from 'web/src/modules/front-notifications/components/FomoBonusNotification/composables/useFomoBonusNotification';
import { useIdentificationPhase } from 'web/src/modules/identification';

export interface UseHeaderProfileComposable {
  bonusValue: Ref<number>;
  isLowBalance: Ref<boolean>;
  isAddBalanceButtonAsCurrency: Ref<boolean>;
  isHiddenBalance: Ref<boolean>;
  isGamblePrevented: Ref<boolean>;
  isBalanceModalShown: Ref<boolean>;
  isBalanceOpened: Ref<boolean>;
  showBonusButton: Ref<boolean>;
  handleTopUp(): void;
  showBalanceModal(): void;
  closeBalanceModal(): void;
  onBonusClick(): void;
}

const storageKey = 'isOpenBalance';

export default function useHeaderProfile(): UseHeaderProfileComposable {
  const bonusStore = useBonusStore();
  const route = useRoute();
  const router = useRouter();
  const siteConfigStore = useSiteConfigStore();

  const customerDataStore = useCustomerDataStore();

  const localStorageManager = useLocalStorageManager();
  const financeStore = useCustomerFinanceStore();
  const { pushToDepositsPage } = usePaymentsDepositsNavigation();
  const analyticsService = useAnalytics();
  const { fomoBonusNotifications } = useFomoBonusNotification();

  const bonusCbc = toRef(bonusStore, 'bonusCbc');
  const bonusValue = computed<number>(() => bonusCbc.value?.walletBalanceNumber || 0);
  const isLowBalance = toRef(financeStore, 'isLowBalance');
  const isAddBalanceButtonAsCurrency = toRef(financeStore, 'isAddBalanceButtonAsCurrency');

  const hasFomoBonus = toRef(() => customerDataStore.hasFomoBonus);
  const fomoBonusActionUrl = toRef(() => customerDataStore.fomoBonusActionUrl);
  const fomoBonusCategoryId = toRef(() => customerDataStore.fomoBonusCategoryId);
  const isFomoTopBarIconEnabled = toRef(() => siteConfigStore.isFomoTopBarIconEnabled);

  const showBonusButton = computed(() => (hasFomoBonus.value || !!fomoBonusNotifications.value.length) && isFomoTopBarIconEnabled.value);
  const actualFomoBonusActionUrl = computed(() => fomoBonusActionUrl.value || fomoBonusNotifications.value[0]?.actionUrl);
  const actualFomoBonusCategoryId = computed(() => fomoBonusCategoryId.value || fomoBonusNotifications.value[0]?.categoryId);

  const isHiddenBalance = computed<boolean>(
    () => {
      if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && isCasinoGameRouteName(route.name)) {
        return true;
      }

      return customerDataStore.isHiddenBalance;
    },
  );
  const isGamblePrevented = process.env.VUE_APP_FEATURE_IDENT_ENABLED
    ? useIdentificationPhase().isGamblePrevented
    : ref(false);

  const isBalanceModalShown = ref<boolean>(false);
  const isBalanceOpened = ref<boolean>(true);

  function handleTopUp(): void {
    analyticsService.clickMap({ deposit: 'headerBalance' });
    pushToDepositsPage();
  }

  function showBalanceModal(): void {
    analyticsService.clickMap({ bonuses: 'bonusBalanceIcon' });
    const storageValue = localStorageManager.getItem(storageKey);
    if (storageValue) {
      isBalanceOpened.value = false;
    } else {
      localStorageManager.setItem(storageKey, '1');
    }
    isBalanceModalShown.value = true;
  }

  function closeBalanceModal(): void {
    isBalanceModalShown.value = false;
  }

  function onBonusClick(): void {
    let location: RouteLocationRaw = {
      name: RouteName.BONUSES,
    };

    if (actualFomoBonusActionUrl.value && actualFomoBonusCategoryId.value) {
      location = {
        name: RouteName.PROMOTION_DETAILS,
        params: {
          categoryId: actualFomoBonusCategoryId.value,
          actionUrl: actualFomoBonusActionUrl.value,
        },
      };
    }

    void router.push(location);
  }

  return {
    bonusValue,
    isLowBalance,
    isAddBalanceButtonAsCurrency,
    isHiddenBalance,
    isGamblePrevented,
    isBalanceModalShown,
    isBalanceOpened,
    showBonusButton,
    handleTopUp,
    showBalanceModal,
    closeBalanceModal,
    onBonusClick,
  };
}
