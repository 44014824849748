import { useThemeStore } from '@core/theme';

import { useCordovaAppStore } from 'web/src/modules/cordova/store';
import { useCordovaIntercomStore } from 'web/src/modules/intercom/store/useCordovaIntercomStore';

export default function initStores(): void {
  useThemeStore();
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    useCordovaAppStore();
    if (process.env.VUE_APP_FEATURE_INTERCOM_ENABLED) {
      useCordovaIntercomStore();
    }
  }
}
