import {
  HomePageType,
  lobbyTypeLiveCasino,
  lobbyTypeSlots,
} from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { useSiteConfigStore } from '@core/site-config';

import { getCasinoLobbyRoutePage } from 'web/src/modules/casino/submodules/lobby/utils';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import {
  getSportlineNavigationRouteComponent,
} from 'web/src/modules/sportline/submodules/navigation/SportlineNavigationSubmodule';

export class HomeModule extends AppModule {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  addRoutes(router: AppVueRouter): void {
    const { isBetSwipeTopEventsLayoutEnabled } = useSportlineLayoutSettings();

    const sportlineNavigation = getSportlineNavigationRouteComponent();

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      const { homePageType } = useSiteConfigStore();

      switch (homePageType) {
        case HomePageType.SLOTS:
        case HomePageType.LIVE_GAMES:
          router.addDefaultRoute({
            ...getCasinoLobbyRoutePage({
              name: RouteName.HOME,
              path: '/',
              meta: {
                routeLobbyType: homePageType === HomePageType.LIVE_GAMES
                  ? lobbyTypeLiveCasino
                  : lobbyTypeSlots,
              },
            }),

            topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
          });
          break;
        case HomePageType.CUSTOM:
          router.addDefaultRoute({
            name: RouteName.HOME,
            path: '/',

            component: () => import('web/src/modules/home/submodules/custom/pages/CustomHomeRoutePage/CustomHomeRoutePage.vue'),
            prefetch: () => import('web/src/modules/home/submodules/custom/prefetch/CustomHomePagePrefetch'),
            meta: {
              isBannerHidden: true,
            },
          });
          break;
        default:
          router.addDefaultRoute({
            name: RouteName.HOME,
            path: '/',
            navigation: sportlineNavigation,
            leftSideBar: process.env.VUE_APP_LAYOUT_DESKTOP
              ? () => import('web/src/modules/sportline/submodules/sidebar/views/SportsSidebarRouteComponent.vue')
              : undefined,

            contentLoader: () => import('web/src/modules/sportline/submodules/navigation/views/NavigationMainContentLoaderRouteComponent/NavigationMainContentLoaderRouteComponent.vue'),
            prefetch: () => import('web/src/modules/home/submodules/casino/prefetch/CasinoHomePagePrefetch'),
            component: () => import('web/src/modules/home/submodules/casino/views/CasinoHomeRoutePage.vue'),

            topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
          });
      }
    } else {
      router.addDefaultRoute({
        name: RouteName.HOME,
        path: '/',
        navigation: sportlineNavigation,
        leftSideBar: process.env.VUE_APP_LAYOUT_DESKTOP
          ? () => import('web/src/modules/sportline/submodules/sidebar/views/SportsSidebarRouteComponent.vue')
          : undefined,

        contentLoader: () => import('web/src/modules/sportline/submodules/navigation/views/NavigationMainContentLoaderRouteComponent/NavigationMainContentLoaderRouteComponent.vue'),
        prefetch: () => import('web/src/modules/home/submodules/sportline/prefetch/HomePagePrefetch'),
        component: process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_ENABLED && isBetSwipeTopEventsLayoutEnabled.value
          ? () => import('web/src/modules/home/submodules/sportline/views/CardEventsHomeRoutePage.vue')
          : () => import('web/src/modules/home/submodules/sportline/views/HomeRoutePage.vue'),

        topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
      });
    }
  }
}
