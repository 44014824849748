import type { AppVueRouter } from '@core/router';
import { useThemeStore } from '@core/theme';

export default function createThemeNavigationGuard(router: AppVueRouter): void {
  const { setCustomTheme } = useThemeStore();

  router.beforeEach((to) => {
    if (process.env.VUE_APP_LAYOUT_PHONE || !to.meta.modalPreset) {
      setCustomTheme(to.meta.theme);
    }
  });
}
