import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class CustomerNotificationsModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addProfileRoute({
      name: RouteName.CUSTOMER_NOTIFICATIONS,
      path: '/notifications',

      component: () => import('web/src/modules/customer-notifications/pages/CustomerNotificationsRoutePage/CustomerNotificationsRoutePage.vue'),
      title: '{{$t(\'JS_OPERATOR_MESSAGES_TITLE\')}}',

      topBar: () => import('web/src/modules/customer-notifications/views/CustomerNotificationsTopBarRouteComponent/CustomerNotificationsTopBarRouteComponent.vue'),
    });

    router.addProfileRoute({
      name: RouteName.CUSTOMER_NOTIFICATION_DETAILS,
      path: '/notifications/:source/:id',

      component: () => import('web/src/modules/customer-notifications/pages/CustomerNotificationDetailsRoutePage/CustomerNotificationDetailsRoutePage.vue'),
      title: '{{$t(\'JS_OPERATOR_MESSAGES_TITLE\')}}',

      topBar: () => import('web/src/modules/customer-notifications/views/CustomerNotificationDetailsTopBarRouteComponent/CustomerNotificationDetailsTopBarRouteComponent.vue'),
    });
  }
}
