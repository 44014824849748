import { logger } from '@leon-hub/logging';

import { useErrorsConverter } from '@core/errors';

export function startAppSw(): Promise<void> {
  return import('web/src/modules/service-worker/utils')
    .then(({ initSw }) => initSw())
    .catch((error) => {
      const appError = useErrorsConverter().convertToBaseError(error, 'Unexpected service-worker error');
      appError.silent = true;
      logger.error(appError);
    });
}
