import type { Debugger } from 'debug';

import { delay } from '@leon-hub/utils';

import { LEXIS_NEXIS_PROFILE_TIMEOUT } from 'web/src/modules/lexis-nexis-integration/constants';

import type { LexisNexisApiProfileOptions } from '../types';
import { createLexisNexisApi } from './createLexisNexisApi';

export async function runLexisNexisApiProfile (options: LexisNexisApiProfileOptions, {
  log,
}: { log: Debugger }): Promise<void> {
  const {
    lexGuardOrgId,
    lexGuardAPIUrl,
    lexGuardProfilingSubdomain,
    sessionId,
    timeout = LEXIS_NEXIS_PROFILE_TIMEOUT,
  } = options;
  log('calling sdk: %O', options);
  const { api, stop } = await createLexisNexisApi(lexGuardAPIUrl);
  try {
    const timeoutTime = Date.now() + timeout;
    // TODO: fix comlink invalid typings of consuming api.
    // @ts-ignore
    api.threatmetrix.profile(lexGuardProfilingSubdomain, lexGuardOrgId, sessionId);

    while (!await api.isProfiled) {
      log('waiting to complete');
      if (Date.now() > timeoutTime) {
        throw new Error('Lexis profile timeout');
      }

      await delay(100);
    }
  } finally {
    stop();
  }
}
