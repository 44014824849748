import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class BonusesModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addProfileRoute({
      name: RouteName.LOYALTY_PROGRAM,
      path: '/profile/loyalty-program',

      component: () => import('./pages/BonusLoyaltyProgramRoutePage/BonusLoyaltyProgramRoutePage.vue'),
      title: '{{$t(\'WEB2_LOYALTY_PROGRAM\')}}',
      topBarProps: {
        hidePrefix: !!process.env.VUE_APP_LAYOUT_DESKTOP,
      },
      prefetch: () => import('./prefetch/BonusLoyaltyProgramPagePrefetch'),
    });

    router.addProfileRoute({
      name: RouteName.SHOP_ITEM_DETAILS,
      path: '/shop/:itemId',

      component: () => import('./pages/BonusLoyaltyShopDetailsRoutePage/BonusLoyaltyShopDetailsRoutePage.vue'),
      props: true,
      title: '{{$t(\'WEB2_LOYALTY_PROGRAM\')}}',
      topBar: () => import('./layouts/BonusLoyaltyShopDetailsTopBar.vue'),
    });
  }
}
