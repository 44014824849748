import type { ToRef } from 'vue';
import { defineStore } from 'pinia';
import {

  computed,
  reactive,
} from 'vue';

import { useCustomerDataStore } from '@core/customer';
import { useSiteConfigStore } from '@core/site-config';

import type { UseLexisNexisIntegrationStore } from '../types';
import { getLexisNexisProfiledStorage } from '../utils/getLexisNexisProfiledStorage';
import { watchCustomEffect } from '../utils/watchCustomEffect';

type CustomerLogin = UseLexisNexisIntegrationStore['customerLogin'];
type ProfiledCache = Partial<Record<string, boolean>>;

export const useLexisNexisIntegrationStore = defineStore('lexis-nexis-integration', (): UseLexisNexisIntegrationStore => {
  const siteConfigStore = useSiteConfigStore();
  const customerDataStore = useCustomerDataStore();

  const profiledCache = reactive<ProfiledCache>({});

  const customerLogin = computed((): string | undefined => customerDataStore.login || undefined);

  const isProfiled = computed(() => (customerDataStore.login ? !!profiledCache[customerDataStore.login] : false));

  const lexGuardAPIUrl = computed(() => siteConfigStore.lexGuardAPIUrl);
  const lexGuardOrgId = computed(() => siteConfigStore.lexGuardOrgId);
  const lexGuardProfilingSubdomain = computed(() => siteConfigStore.lexGuardProfilingSubdomain);
  const isEnabled = computed<boolean>(() => !!(
    siteConfigStore.isLexGuardEnabled
    && siteConfigStore.lexGuardAPIUrl
    && siteConfigStore.lexGuardOrgId
    && siteConfigStore.lexGuardProfilingSubdomain
  ));

  startSyncStorageToRefs(customerLogin, profiledCache, isEnabled);

  return {
    customerLogin,
    setProfiled(login: string) {
      if (login !== '') {
        profiledCache[login] = true;
      }
    },
    isProfiled,
    isEnabled,
    lexGuardOrgId,
    lexGuardAPIUrl,
    lexGuardProfilingSubdomain,
  };
});

function startSyncStorageToRefs(
  login: CustomerLogin,
  profiledCache: Partial<Record<string, boolean>>,
  isEnabled: ToRef<unknown>,
) {
  watchCustomEffect(async () => {
    if (!login.value) {
      return;
    }
    Object.assign(profiledCache, {
      [login.value]: await getLexisNexisProfiledStorage(login.value).isProfiled(),
    });
  }, {
    id: 'persist-storage',
    condition: isEnabled,
  });
}
