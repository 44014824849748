import createDebug from 'debug';

import { getBuiltinConfig } from '@leon-hub/builtin-config';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

import type { TranslationDocument } from '@core/i18n';
import { useCoreApi, useGraphqlClient } from '@core/app-rest-client';
import { fetchApiSettings } from '@core/app-settings';
import { fetchTranslations, primaryKeys } from '@core/i18n';
import { fetchDefaultNavigationConfig } from '@core/navigation';
import { makeRootSettingsRequest } from '@core/root';
import { fetchRoutingConfigs } from '@core/router';
import { fetchSiteConfigSettings } from '@core/site-config';

import type { PrefetchProducer } from './types/PrefetchProducer';

let promise: undefined | Promise<PrefetchProducer>;
const debug = createDebug('nuxt');
debug('TODO: reset memoized createPrefetchProducer per ssr request');
export async function createPrefetchProducer(): Promise<PrefetchProducer> {
  // Memoized result.
  if (promise)
    return promise;
  let resolve: (value: PrefetchProducer | PromiseLike<PrefetchProducer>) => void;
  promise = new Promise((r) => {
    resolve = r;
  });

  async function getI18n(): Promise<readonly TranslationDocument[]> {
    const i18nUrl = getBuiltinConfig().i18nUrl;
    try {
      if (i18nUrl) {
        const data = await useCoreApi().loadTranslations(i18nUrl);
        return Object.entries(data).map(([key, value]) => ({ key, value }));
      }
    } catch (err) {
      logger.error('i18n load error', normalizeError(err));
    }
    return fetchTranslations(primaryKeys);
  }

  const data: PrefetchProducer = {
    root: makeRootSettingsRequest(),
    api: fetchApiSettings(),
    siteConfig: fetchSiteConfigSettings(),
    routing: fetchRoutingConfigs(),
    navigation: fetchDefaultNavigationConfig(),
    i18n: getI18n(),
    serverTime: useCoreApi().syncServerTime(),
  };

  // Make sure to flush all pending requests.
  await useGraphqlClient().flush({ inParallel: true });
  // Resolve only after result is resolved for next caller.
  queueMicrotask(() => resolve(data));
  return data;
}
