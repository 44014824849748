import type { AsyncComponentLoader } from 'vue';

import {
  CasinoRouteName,
  RouteName,
  TsupisRouteName,
} from '@leon-hub/routing-config-names';

import type {
  AppVueRouter,
  BaseTopBarRouteConfig,
  DefaultRouteConfig,
  ModalRouteConfig,
} from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

interface CmsRouteConfig {
  name: DefaultRouteConfig['name'];
  path: DefaultRouteConfig['path'];
  title: string;
  cmsKey: string;
  customComponent?: AsyncComponentLoader;
  isBigTitle?: boolean;
}

function isPropsRouteName(name: CmsRouteConfig['name']): boolean {
  return name === RouteName.CMS || name === RouteName.CMS_PROMO_TERMS;
}

function getComponentPath(customComponent: AsyncComponentLoader | undefined) {
  return customComponent ?? (() => import('./components/VCmsContent/VCmsContent.vue'));
}

function getDefaultDesktopCmsRouteConfig({
  name,
  path,
  title,
  cmsKey,
  isBigTitle,
}: CmsRouteConfig): DefaultRouteConfig {
  if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
    throw new Error('Only desktop layout');
  }

  return {
    name,
    path,
    title,
    isBigTitle,
    component: () => import('./components/VCmsContent/VCmsContent.vue'),
    props: isPropsRouteName(name)
      ? true
      : {
          cmsKey,
        },
    leftSideBar: () => import('web/src/modules/sidebar/views/SidebarMenuKnowHowRouteComponent/SidebarMenuKnowHowRouteComponent.vue'),
  };
}

function getDefaultModalCmsRouteConfig({
  name,
  path,
  title,
  cmsKey,
  customComponent,
}: CmsRouteConfig, modalPreset: ModalDesktopPreset): ModalRouteConfig {
  if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
    throw new Error('Only desktop layout');
  }

  return {
    name,
    path,
    modalPreset,
    component: getComponentPath(customComponent),
    props: isPropsRouteName(name)
      ? true
      : {
          cmsKey,
        },
    title,
  };
}

function getPhoneCmsRouteConfig({
  name,
  path,
  title,
  cmsKey,
  customComponent,
}: CmsRouteConfig): BaseTopBarRouteConfig {
  if (!process.env.VUE_APP_LAYOUT_PHONE) {
    throw new Error('Only phone layout');
  }

  return {
    name,
    path,
    component: getComponentPath(customComponent),
    props: isPropsRouteName(name)
      ? true
      : {
          cmsKey,
        },
    title,
  };
}

export function addCmsRoute(router: AppVueRouter, config: CmsRouteConfig, modalPreset?: ModalDesktopPreset): void {
  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    if (!modalPreset) {
      router.addDefaultRoute(getDefaultDesktopCmsRouteConfig(config));
    } else {
      router.addModalRoute(getDefaultModalCmsRouteConfig(config, modalPreset));
    }
  }

  if (process.env.VUE_APP_LAYOUT_PHONE) {
    router.addModalRoute(getPhoneCmsRouteConfig(config));
  }
}

export class CmsModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    addCmsRoute(router, {
      name: RouteName.CMS,
      path: '/cms/:cmsKey',
      cmsKey: '',
      title: '',
    });

    addCmsRoute(router, {
      name: RouteName.ABOUT_COMPANY_WEB2,
      path: '/about',
      cmsKey: 'ABOUT_COMPANY_WEB2',
      title: '{{$t(\'WEB2_ROUTING_TITLE_ABOUT_COMPANY\')}}',
      isBigTitle: true,
    });

    addCmsRoute(router, {
      name: RouteName.AFFILIATE_PROGRAM_RULES_WEB2,
      path: '/affiliate-program-rules',
      cmsKey: process.env.VUE_APP_LAYOUT_PHONE ? 'WEB2_AFFILIATE_RULES_MOBILE' : 'WEB2_AFFILIATE_RULES',
      title: '{{$t(\'WEB2_AFFILIATE_PROGRAM_TERMS_TITLE\')}}',
    }, ModalDesktopPreset.LargeDesktopModal);

    addCmsRoute(router, {
      name: RouteName.BONUS_PROGRAM_CONDITIONS_WEB2,
      path: '/uslovija-bonusnoj-programmy',
      cmsKey: 'BONUS_PROGRAM_CONDITIONS_WEB2',
      title: '{{$t(\'WEB2_BONUS_CAMPAIGN_TERMS_TITLE\')}}',
    }, ModalDesktopPreset.SmallDesktopModal);

    addCmsRoute(router, {
      name: RouteName.SPORT_BETTING_RULES_WEB2,
      path: '/rules',
      cmsKey: process.env.VUE_APP_LAYOUT_PHONE ? 'SPORT_BETTING_GUIDELINES_MOBILE_WEB2' : 'SPORT_BETTING_GUIDELINES_DESKTOP_WEB2',
      title: '{{$t(\'WEB2_SPORT_BETTING_RULES_TITLE\')}}',
      isBigTitle: true,
    });

    addCmsRoute(router, {
      name: RouteName.CMS_DATA_PROCESSING_AGREEMENT,
      path: '/data-processing-agreement',
      cmsKey: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? 'SECURITY_AND_PRIVACY_WEB2'
        : 'DATA_PROCESSING_AGREEMENT_WEB2',
      title: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? '{{$t(\'WEB2_SECURITY_AND_PRIVACY\')}}'
        : '{{$t(\'WEB2_DATA_PROCESSING_AGREEMENT\')}}',
    }, ModalDesktopPreset.LargeDesktopModal);

    addCmsRoute(router, {
      name: RouteName.CMS_BETTING_PARTICIPATION_AGREEMENT,
      path: '/betting-participation-agreement',
      cmsKey: process.env.VUE_APP_LAYOUT_PHONE ? 'REG_GUIDELINES_MOBILE_WEB2' : 'REG_GUIDELINES_DESKTOP_WEB2',
      title: '{{$t(\'WEB2_REG_RULES_TITLE\')}}',
    }, ModalDesktopPreset.LargeDesktopModal);

    addCmsRoute(router, {
      name: RouteName.CMS_HOW_TO_BET,
      path: '/make-a-bet',
      cmsKey: 'HOW_TO_BET_WEB2',
      title: '{{$t(\'WEB2_ROUTING_TITLE_HOW_TO_BET\')}}',
    });

    addCmsRoute(router, {
      name: RouteName.CONTACTS,
      path: '/contacts',
      cmsKey: 'WEB2_CONTACTS',
      title: '{{$t(\'JS_WEB2_NAVIGATION_CONTACTS\')}}',
      isBigTitle: true,
    });

    addCmsRoute(router, {
      name: RouteName.REFERRAL_PROGRAM_AGREEMENT,
      path: '/referral-program-agreement',
      cmsKey: 'WEB2_REFERRAL_PROGRAM_AGREEMENT_DESCRIPTION',
      title: '{{$t(\'WEB2_REFERRAL_PROGRAM_AGREEMENT_TITLE\')}}',
    }, ModalDesktopPreset.LargeDesktopModal);

    if (!process.env.VUE_APP_PRODUCT_LEONRU) {
      addCmsRoute(router, {
        name: RouteName.RESPONSIBLE_GAMBLING_WEB2,
        path: '/responsible-gambling',
        cmsKey: 'RESPONSIBLE_GAMBLING_WEB2',
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMING\')}}',
      });

      addCmsRoute(router, {
        name: RouteName.RESPONSIBLE_GAMBLING_WEB2_MODAL,
        path: '/responsible-gambling-modal',
        cmsKey: 'RESPONSIBLE_GAMBLING_WEB2',
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMING\')}}',
      }, ModalDesktopPreset.LargeDesktopModal);

      addCmsRoute(router, {
        name: CasinoRouteName.WEB2_CASINO_RULES,
        path: '/casino-rules',
        cmsKey: 'WEB2_CASINO_RULES',
        title: '{{$t(\'WEB2_CASINO_RULES\')}}',
        isBigTitle: true,
      });

      addCmsRoute(router, {
        name: RouteName.TELEGRAM_BOT,
        path: '/telegram-bot',
        cmsKey: 'WEB2_TELEGRAM_INFO',
        title: '{{$t(\'WEB2_TELEGRAM_BOT_TTL\')}}',
      });
    }

    if (process.env.VUE_APP_PRODUCT_LEONRU) {
      addCmsRoute(router, {
        name: TsupisRouteName.IDENT_GENERAL_INFO_WEB2,
        path: '/identification',
        cmsKey: 'IDENT_GENERAL_INFO_WEB2',
        title: '{{$t(\'JS_MOBILE_IDENTIFICATION_V5\')}}',
      });

      addCmsRoute(router, {
        name: RouteName.CMS_LEGAL_DOCUMENTS,
        path: '/docs',
        cmsKey: 'LEON_DOCS_WEB2',
        title: process.env.VUE_APP_LAYOUT_PHONE
          ? 'Документы'
          : 'Регламентирующие документы легальной букмекерской конторы Леон',
        isBigTitle: true,
      });
    }

    if (process.env.VUE_APP_PRODUCT_SLOTT) {
      addCmsRoute(router, {
        name: RouteName.CMS_SECURITY_AND_PRIVACY,
        path: '/security-and-privacy',
        cmsKey: 'SECURITY_AND_PRIVACY_WEB2',
        title: '{{$t(\'WEB2_SECURITY_AND_PRIVACY\')}}',
      });
    }
  }
}
