import { toRef } from 'vue';

import { assert } from '@leon-hub/guards';

import { useCustomerBalanceUpdateStore } from '@core/money';
import { useSiteConfigStore } from '@core/site-config';
import { useUserStore } from '@core/user';

import useBenefitProgramStore from 'web/src/modules/benefits-program/store/useBenefitProgramStore';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { useInitialRequests } from 'web/src/modules/core/components/composables';
import { useFortuneWheelStore } from 'web/src/modules/fortune-wheel/store';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import useResponsibleGamblingStoreV2 from 'web/src/modules/profile/submodules/responsible-gambling/store/useResponsibleGamblingStoreV2';
import useReferralProgramStore from 'web/src/modules/referral-program/store/useReferralProgramStore';

import { getEgsLoyaltyStore } from './utils';

export interface UseAuthorizedHandlerComposable {
  onLogin(isInit?: boolean): Promise<void>;
}

export default function useAuthorizedHandler(): UseAuthorizedHandlerComposable {
  const egsLoyaltyStore = getEgsLoyaltyStore();
  const frontNotificationsStore = useFrontNotificationsStore();
  const referralProgramStore = useReferralProgramStore();
  const bonusStore = useBonusStore();
  const siteConfigStore = useSiteConfigStore();
  const { loadData: loadBalance } = useCustomerBalanceUpdateStore();
  const { loadUserData } = useUserStore();
  const { getInitRequests } = useInitialRequests();
  const { init: initResponsibleGambling } = useResponsibleGamblingStoreV2();
  const benefitProgramStore = useBenefitProgramStore();

  const isProfileReferralProgramEnabled = toRef(() => siteConfigStore.isProfileReferralProgramEnabled);

  async function onLogin(isInit?: boolean): Promise<void> {
    const requests: Promise<unknown>[] = [];

    if (isInit) {
      requests.push(
        loadUserData(),
        ...getInitRequests(),
      );
    }

    if (isProfileReferralProgramEnabled.value) {
      requests.push(referralProgramStore.getAvailability());
    }

    const {
      fetchActiveOrAvailableCampaigns,
    } = useFortuneWheelStore();

    requests.push(
      frontNotificationsStore.getUnreadNotifications(true),
      bonusStore.getBonusWalletTransferAmount(),
      bonusStore.fetchBonusWagers(),
      loadBalance(),
      fetchActiveOrAvailableCampaigns(),
      initResponsibleGambling(),
    );

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      assert(egsLoyaltyStore);

      requests.push(egsLoyaltyStore.fetchCasinoLoyaltyStatus());
    }

    if (process.env.VUE_APP_FEATURE_BENEFITS_PROGRAM_ENABLED) {
      assert(benefitProgramStore);
      requests.push(benefitProgramStore.fetchBenefitProgramStatus());
    }

    await Promise.all(requests);
  }

  return {
    onLogin,
  };
}
