import { toRef } from 'vue';

import { themeDark } from '@leon-hub/api-sdk';
import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

export class LandingsModule extends AppModule {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  addRoutes(router: AppVueRouter): void {
    const isLandingFestivalEnabled = toRef(useSiteConfigStore(), 'isLandingFestivalEnabled');
    const isLandingVipEnabled = toRef(useSiteConfigStore(), 'isLandingVipEnabled');
    const isAdventCalendarEnabled = toRef(useSiteConfigStore(), 'isAdventCalendarEnabled');

    if (process.env.VUE_APP_FEATURE_LANDING_COUPONS_ENABLED) {
      router.addLandingRoute({
        name: RouteName.COUPONS,
        path: '/my-coupons/:id?',
        component: () => import('./submodules/coupons/pages/LandingCouponsPage.vue'),
      });
    }

    if (process.env.VUE_APP_FEATURE_EURO_2024_LANDING_ENABLED && isLandingFestivalEnabled.value) {
      router.addDefaultRoute({
        name: RouteName.EURO_2024,
        path: '/euro-2024',
        component: () => import('./submodules/euro-2024/pages/Euro2024RoutePage/Euro2024RoutePage.vue'),
        theme: themeDark,
      });

      router.addModalRoute({
        name: RouteName.EURO_2024_BATTLE_PASS,
        path: '/euro-2024/battle-pass',
        access: CustomerRouteAccessRole.AUTHORIZED,
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP
          ? ModalDesktopPreset.EuroBattlePassModal
          : undefined,
        title: '{{$t(\'WEB2_LANDING_EURO_BATTLE_PASS_CARD_GET_PRIZES\')}}',
        component: () => import('./submodules/euro-2024/pages/Euro2024BattlePassRoutePage/Euro2024BattlePassRoutePage.vue'),
        topBar: !process.env.VUE_APP_LAYOUT_DESKTOP
          ? () => import('./submodules/euro-2024/components/LandingEuroBattlePassTopBarRouteComponent/LandingEuroBattlePassTopBarRouteComponent.vue')
          : undefined,
        topBarProps: {
          hidePrefix: !!process.env.VUE_APP_LAYOUT_DESKTOP,
        },
        theme: themeDark,
      });

      if (process.env.VUE_APP_FEATURE_EURO_2024_LANDING_BONUS_INFO_ENABLED) {
        router.addModalRoute({
          name: RouteName.EURO_2024_BONUS_INFO,
          path: '/euro-2024/battle-pass/bonus-info',
          access: CustomerRouteAccessRole.AUTHORIZED,
          modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP
            ? ModalDesktopPreset.EuroBattlePassModal
            : undefined,
          component: () => import('./submodules/euro-2024/pages/Euro2024BonusesInfoRoutePage/Euro2024BonusesInfoRoutePage.vue'),
          topBar: () => import('./submodules/euro-2024/components/LandingEuroBattlePassTopBarRouteComponent/LandingEuroBattlePassTopBarRouteComponent.vue'),
          title: '{{$t(\'WEB2_LANDING_EURO_HOW_TO_USE_BONUSES_MODAL_TITLE\')}}',
          theme: themeDark,
        });
      }
    }

    if (process.env.VUE_APP_FEATURE_APP_DOWNLOAD_ENABLED) {
      router.addLandingRoute({
        name: RouteName.ANDROID,
        path: '/android',
        component: () => (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? import('./submodules/android/pages/AndroidRoutePage.vue')
          : import('./submodules/android/AndroidRoutePage.vue')),
      });
    }

    if (process.env.VUE_APP_FEATURE_LANDING_VIP_ENABLED && isLandingVipEnabled.value) {
      router.addDefaultRoute({
        name: RouteName.VIP,
        path: '/vip',
        component: () => import('./submodules/vip/pages/LandingVipRoutePage.vue'),
        isLeftSidebarToggleHidden: true,
      });
    }

    if (process.env.VUE_APP_FEATURE_LANDING_ADVENT_CALENDAR_ENABLED && isAdventCalendarEnabled.value) {
      router.addDefaultRoute({
        name: RouteName.BONUS_CALENDAR,
        path: '/bonus-calendar',
        component: () => import('./submodules/advent/pages/AdventRoutePage/AdventRoutePage.vue'),
        theme: themeDark,
      });

      router.addModalRoute({
        name: RouteName.BONUS_CALENDAR_PRIZES,
        path: '/bonus-calendar/prizes',
        access: CustomerRouteAccessRole.AUTHORIZED,
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP
          ? ModalDesktopPreset.EuroBattlePassModal
          : undefined,
        title: '{{$t(\'WEB2_LANDING_ADVENT_MODAL_GET_PRIZES_TITLE\')}}',
        component: () => import('./submodules/advent/pages/AdventPrizesModalRoutePage/AdventPrizesModalRoutePage.vue'),
        topBar: !process.env.VUE_APP_LAYOUT_DESKTOP
          ? () => import('./submodules/euro-2024/components/LandingEuroBattlePassTopBarRouteComponent/LandingEuroBattlePassTopBarRouteComponent.vue')
          : undefined,
        topBarProps: {
          hidePrefix: !!process.env.VUE_APP_LAYOUT_DESKTOP,
        },
        theme: themeDark,
      });
    }

    if (process.env.VUE_APP_FEATURE_LOYALTY_LANDING_ENABLED) {
      router.addDefaultRoute({
        name: RouteName.LOYALTY_PROGRAM_LANDING,
        path: '/loyalty-program-landing',
        component: () => import('./submodules/loyalty/LoyaltyRoutePage.vue'),
        theme: themeDark,
      });
    }
  }
}
