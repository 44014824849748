import {
  QueryParameterGroupName,
} from '@leon-hub/query-manager';

import type { AppVueRouter } from '@core/router';
import { useQueryManager } from '@core/query-manager';
import { useRootStore } from '@core/root';

export default function createQueryNavigationGuard(router: AppVueRouter): void {
  const queryManager = useQueryManager();
  const rootStore = useRootStore();

  router.beforeEach((to, from) => {
    if (from.name && rootStore.isDirectLink) {
      rootStore.setDirectLinkState(false);
    }

    // Apply sticky query parameters within app routing.
    const query = queryManager.getParameters({
      booleanAsString: true,
      group: [
        QueryParameterGroupName.APP,
        QueryParameterGroupName.TESTING,
        QueryParameterGroupName.BONUS,
      ],
    });

    if (Object.keys(query).length) {
      const queryUpdate = { ...to.query, ...query };

      if (JSON.stringify(to.query) !== JSON.stringify(queryUpdate)) {
        return router.next({
          name: to.name || undefined,
          params: to.params,
          query: queryUpdate,
        });
      }
    }
  });
}
