<script lang="ts" setup>
import type { GuestAppControllerEmits, GuestAppControllerProps } from './types';
import { useGuestAppController } from './composables';

const props = withDefaults(defineProps<GuestAppControllerProps>(), {});
const emit = defineEmits<GuestAppControllerEmits>();

useGuestAppController(props, emit);
</script>

<template>
  <div v-auto-id="'GuestAppController'" />
</template>
