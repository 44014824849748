import type { App } from 'vue';
import { toRef, watch } from 'vue';

import type { IgnoredError } from '@leon-hub/api-sdk';
import type {
  SentryObject,
} from '@leon-hub/sentry-types';
import { ErrorHandlerType } from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';
import { isLoggableError } from '@leon-hub/sentry';

import type { AppVueRouter } from '@core/router';
import { useCustomerDataStore } from '@core/customer';
import { useSiteConfigStore } from '@core/site-config';

import { getInitSentryOptions } from './getInitSentryOptions';
import { trackEvents } from './trackEvents';

function mergeIgnoredErrors(
  ignoredErrors1: IgnoredError[],
  ignoredErrors2: IgnoredError[],
): IgnoredError[] {
  return [...new Map([...ignoredErrors1, ...ignoredErrors2]
    .map((e) => [JSON.stringify([e.error, e.type]), e]))
    .values(),
  ];
}

export default async function startSentry(app: App, router: AppVueRouter): Promise<void> {
  const {
    errorHandlerSettings,
    ignoredErrorsCommon,
    sentryOptions,
  } = useSiteConfigStore();
  const login = toRef(useCustomerDataStore(), 'login');

  const version = process.env.VUE_APP_VERSION;
  assert(version, 'Expected name to be defined');

  if (errorHandlerSettings
    && errorHandlerSettings.enabled
    && errorHandlerSettings.errorHandlerType === ErrorHandlerType.SENTRY) {
    const ignoredErrors = mergeIgnoredErrors(ignoredErrorsCommon, errorHandlerSettings.ignoredErrors);

    let SentryInstance: SentryObject;

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const [
        {
          SentryCordova,
        },
        {
          captureConsoleIntegration,
          extraErrorDataIntegration,
        },
      ] = [
        await import('@leon-hub/cordova'),
        await import('@leon-hub/sentry'),
      ];

      const tags = {
        release: version,
        product: process.env.VUE_APP_PRODUCT,
        skin: process.env.VUE_APP_SKIN,
        platform: process.env.VUE_APP_PLATFORM,
      };

      SentryInstance = SentryCordova.initCordovaSentry({
        ...sentryOptions,
        transportOptions: {
          ...sentryOptions.transportOptions ?? {},
          fetchOptions: {
            ...(sentryOptions.transportOptions as Record<string, unknown>)?.fetchOptions ?? {},
            priority: 'low',
          },
        },
        initialScope: {
          tags,
        },
        integrations: [
          captureConsoleIntegration({
            levels: errorHandlerSettings.consoleLogLevels,
          }),
          extraErrorDataIntegration(),
        ],
        beforeSend: (event: unknown, hint: Record<string, unknown>) => {
          if (hint?.originalException && !isLoggableError(hint.originalException, ignoredErrors)) {
            return null;
          }

          return event;
        },
      });

      if (SentryInstance) {
        // hack to set tags for android native logger, since the logger is instantiated asynchronously
        setTimeout(() => {
          SentryInstance.setTags(tags);
        }, 1000);
      }
    } else {
      const { initSentry } = await import('web/src/modules/sentry/utils');
      SentryInstance = initSentry(app, router, getInitSentryOptions());
    }

    if (SentryInstance) {
      trackEvents();
      const setUser = (newValue: string) => SentryInstance.setUser(newValue ? { id: newValue } : null);

      watch(login, (newValue) => {
        if (process.env.VUE_APP_PLATFORM_CORDOVA) {
          setTimeout(() => setUser(newValue), 1000);
        } else {
          setUser(newValue);
        }
      }, { immediate: true });
    }
  }
}
