import { watch } from 'vue';

import { startLoginDeviceRoutineEventType, useAppEmitter } from '@core/app-emitter';
import { useIsLoggedIn } from '@core/auth';

import { runDeviceIdRoutine } from 'web/src/modules/auth/utils';

export function setupEmitter(): void {
  useAppEmitter().on(startLoginDeviceRoutineEventType, (): void => {
    const { isLoggedIn } = useIsLoggedIn();
    if (isLoggedIn.value) {
      void runDeviceIdRoutine();
    } else {
      watch(isLoggedIn, () => {
        void runDeviceIdRoutine();
      }, { once: true });
    }
  });
}
