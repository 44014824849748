import type { App } from 'vue';

import type { EventsBus } from '@leon-hub/event-bus';
import { ComponentError, ComponentWarning, UnhandledRejection } from '@leon-hub/app-errors';
import { SentryCordova } from '@leon-hub/cordova';
import { AbstractError } from '@leon-hub/errors';
import { isOpenSearchLoggableError, isSentryLoggableError } from '@leon-hub/errors-whitelist';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { logger } from '@leon-hub/logging';

function isHandleableError(argument: unknown): argument is AbstractError {
  return argument instanceof AbstractError;
}

function handleError({ error, bus, event }: { error: Error; bus: EventsBus; event?: Event }): void {
  bus.emit(BusEvent.HANDLE_ERROR, {
    error,
    event,
  });
}

function setupGlobalUnhandledRejectionError(bus: EventsBus): void {
  if (process.env.VUE_APP_RENDERING_CSR) {
    window.addEventListener('unhandledrejection', (event) => {
      try {
        if (event.defaultPrevented) {
          return;
        }

        event.preventDefault();
        if (isHandleableError(event.reason)) {
          handleError({ error: event.reason, bus, event });
          return;
        }

        if (isOpenSearchLoggableError(event.reason)) {
          logger.error(new UnhandledRejection({
            event,
          }));
        }
      } catch (error) {
        logger.error('Unhandled Promise Rejection processing error', {
          event,
          error,
        });
      }
    });
  }
}

export default function configureErrorHandler(app: App): App {
  const bus = useEventsBus();

  setupGlobalUnhandledRejectionError(bus);

  app.config.errorHandler = (error: Error, vm, info): void => {
    if (isHandleableError(error)) {
      handleError({ error, bus });
      return;
    }

    if (isOpenSearchLoggableError(error)) {
      const componentError = new ComponentError({
        prefix: '[Vue ERROR]',
        originalError: error,
        info,
        vm,
      });

      logger.error(componentError);
    }

    if (process.env.VUE_APP_PLATFORM_CORDOVA && isSentryLoggableError(error)) {
      SentryCordova.captureException(error);
    }
  };

  app.config.warnHandler = (message, vm, trace): void => {
    const componentWarning = new ComponentWarning({
      message,
      trace,
      vm,
    });

    logger.warn(componentWarning);
  };

  return app;
}
