import type { AppVueRouter } from '@core/router';
import { useNavigationBackButtonStore } from '@core/navigation';

export function createHeaderBackButtonNavigationGuard(router: AppVueRouter): void {
  const { setNavigationBackLocationGetter } = useNavigationBackButtonStore();

  router.beforeEach(() => {
    setNavigationBackLocationGetter(null);
  });
}
