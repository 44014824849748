import { Json, requestIdleCallback, Timer, ScriptElement } from "@leon-hub/utils";
import { useAnalytics } from "@core/analytics";
import throttle from "lodash/throttle";
import { isNullOrUndefined, assert, isFunction } from "@leon-hub/guards";
import { logger } from "@leon-hub/logging";
import { Events, getYMInstance } from "@leon-hub/yandex-metrika";
import { toRef, computed, watch } from "vue";
import { GoogleService, ZetaDspService } from "@leon-hub/metrics";
import { defineStore } from "pinia";
import { metricsToolTypeYandex, metricsToolTypeGoogleAnalytics, zetaDcpConfigTypeAll, zetaDcpConfigTypeDeposit, zetaDcpConfigTypeFirstTimeDeposit, zetaDcpConfigTypeRegistration, zetaDcpConfigTypeLanding } from "@leon-hub/api-sdk";
import { useSiteConfigStore } from "@core/site-config";
import { useCustomerDataStore } from "@core/customer";
import { useTheme } from "@core/theme";
import { getObjectFromPairs } from "@leon-hub/api-utils";
import { whenClientNetworkIdle } from "@leon-hub/idle";
var CollectMethod = /* @__PURE__ */ ((CollectMethod2) => {
  CollectMethod2["ClickCounter"] = "click-counter";
  return CollectMethod2;
})(CollectMethod || {});
const directiveName = "collectClickDirective";
const empiricalThrottleTimeMs = 150;
const listeners = /* @__PURE__ */ new Map();
const datasetCollectKey = "collectKey";
const isEventHandler = (value) => isFunction(value);
function mapToAnalyticsEvent(method, payload) {
  let groupName = null;
  let eventName = null;
  {
    groupName = "clickCounter";
    eventName = Events.CLICK_MAP;
  }
  assert(groupName && eventName, "Wrong collect method.");
  return {
    name: eventName,
    payload: {
      [groupName]: {
        ...payload
      }
    }
  };
}
function attachListener(element, handler) {
  const listenerKey = element.dataset[datasetCollectKey];
  assert(typeof listenerKey !== "undefined", `[${directiveName}] dataset listenerKey should be exist.`);
  listeners.set(listenerKey, handler);
  element.addEventListener("click", handler);
}
function detachListener(element) {
  const key = element.dataset[datasetCollectKey];
  assert(typeof key !== "undefined", `[${directiveName}:detachListener] dataset key should be present.`);
  const handler = listeners.get(key);
  assert(
    isEventHandler(handler),
    `[${directiveName}:detachListener] provided handler should be of type EventHandler.`
  );
  element.removeEventListener("click", handler);
}
function getDirective() {
  return {
    beforeMount(element, binding) {
      if (isNullOrUndefined(element)) {
        return;
      }
      const elementToListen = element;
      assert(
        // eslint-disable-next-line unicorn/prefer-includes
        Object.values(CollectMethod).some((item) => item === binding.arg),
        `[${directiveName}] an argument should be one of CollectMethod.`
      );
      const eventData = mapToAnalyticsEvent("click-counter", binding.value);
      const eventDataKey = Json.stringify(eventData, {
        defaultValue: null
      });
      assert(!isNullOrUndefined(eventDataKey), "eventDataKey should have valid value.");
      elementToListen.dataset[datasetCollectKey] = eventDataKey;
      const handler = () => requestIdleCallback.bind(
        null,
        useAnalytics().push.bind(null, eventData.name, eventData.payload)
      )();
      attachListener(
        elementToListen,
        (binding == null ? void 0 : binding.modifiers.throttle) ? throttle(handler, empiricalThrottleTimeMs) : handler
      );
    },
    beforeUnmount(element) {
      if (element) {
        detachListener(element);
      } else {
        logger.warn(`[${directiveName}:unbind] HTMLElement should be exist.`);
      }
    }
  };
}
const CollectClickPlugin = {
  install(app) {
    app.directive("collect", getDirective());
  }
};
function findMetricsToolsByType(type, config) {
  var _a;
  return (_a = (config ?? []).find((item) => item.type === type)) == null ? void 0 : _a.setup;
}
function findZetaDspConfigParamsByType(type, config) {
  var _a;
  return ((_a = config.find((item) => item.type === type)) == null ? void 0 : _a.params) ?? [];
}
function deserializeNumber(value) {
  const number = Number.parseInt(value, 10);
  return Number.isNaN(number) ? value : number;
}
function deserializeBoolean(value) {
  return value === "false" || value === "true" ? JSON.parse(value) : value;
}
function deserializeValue(value) {
  const result = deserializeNumber(value);
  return typeof result === "string" ? deserializeBoolean(result) : result;
}
function parseMetricsToolConfig(config) {
  const result = {};
  for (const { key, value } of config ?? []) {
    result[key] = deserializeValue(value);
  }
  return result;
}
const useMetricsStore = /* @__PURE__ */ defineStore("metrics", () => {
  const siteConfigStore = useSiteConfigStore();
  const metricsToolsData = toRef(siteConfigStore, "metricsToolsBlock");
  const zetaDSPisEnabled = toRef(siteConfigStore, "zetaDSPisEnabled");
  const zetaDSPMainType = toRef(siteConfigStore, "zetaDSPMainType");
  const zetaDSPConfig = toRef(siteConfigStore, "zetaDSPConfig");
  const sportradarCounterId = toRef(siteConfigStore, "sportradarCounterId");
  const sportradar = toRef(siteConfigStore, "sportradar");
  const yandexMetrikaConfig = computed(() => {
    var _a;
    const config = findMetricsToolsByType(metricsToolTypeYandex, (_a = metricsToolsData.value) == null ? void 0 : _a.metricsToolsData);
    return config ? {
      id: Number(config.counterId),
      ...parseMetricsToolConfig(config.config)
    } : void 0;
  });
  const hasYandexMetrikaConfig = computed(() => !!yandexMetrikaConfig.value);
  const googleCounterId = computed(() => {
    var _a;
    const config = findMetricsToolsByType(metricsToolTypeGoogleAnalytics, (_a = metricsToolsData.value) == null ? void 0 : _a.metricsToolsData);
    return config == null ? void 0 : config.counterId;
  });
  const zetaDspAll = computed(() => findZetaDspConfigParamsByType(zetaDcpConfigTypeAll, zetaDSPConfig.value));
  const zetaDspDeposit = computed(() => findZetaDspConfigParamsByType(zetaDcpConfigTypeDeposit, zetaDSPConfig.value));
  const zetaDspFTD = computed(() => findZetaDspConfigParamsByType(zetaDcpConfigTypeFirstTimeDeposit, zetaDSPConfig.value));
  const zetaDspRegistration = computed(() => findZetaDspConfigParamsByType(zetaDcpConfigTypeRegistration, zetaDSPConfig.value));
  const zetaDspLanding = computed(() => findZetaDspConfigParamsByType(zetaDcpConfigTypeLanding, zetaDSPConfig.value));
  const sportradarPixelManager = computed(() => {
    var _a;
    return (_a = sportradar.value) == null ? void 0 : _a.pixelManager;
  });
  return {
    zetaDSPisEnabled,
    zetaDSPMainType,
    yandexMetrikaConfig,
    hasYandexMetrikaConfig,
    sportradarCounterId,
    googleCounterId,
    zetaDspAll,
    zetaDspDeposit,
    zetaDspRegistration,
    zetaDspFTD,
    zetaDspLanding,
    sportradarPixelManager
  };
});
function initGoogleAnalytics() {
  const metricsStore = useMetricsStore();
  const googleCounterId = toRef(metricsStore, "googleCounterId");
  watch(googleCounterId, (newValue) => {
    if (newValue) {
      GoogleService.getInstance().setCounterId(newValue);
    }
  }, {
    immediate: true
  });
}
async function registerYandexMetrika(config) {
  const YM = getYMInstance();
  if (config) {
    try {
      await YM.register(config, {
        collectQueues: true
      });
    } catch (rawError) {
      const errorMessage = `YandexMetrika registration error: config=${Json.stringify(config)}`;
      logger.warn(errorMessage, rawError);
    }
  }
}
function initYM({
  initTimeout: initTimeout2
}) {
  const metricsStore = useMetricsStore();
  const hasYandexMetrikaConfig = toRef(metricsStore, "hasYandexMetrikaConfig");
  const yandexMetrikaConfig = toRef(metricsStore, "yandexMetrikaConfig");
  const customerDataStore = useCustomerDataStore();
  const analytics = useAnalytics();
  const login = toRef(customerDataStore, "login");
  const countryName = toRef(customerDataStore, "countryName");
  const vipStatus = toRef(customerDataStore, "vipStatus");
  const YM = getYMInstance();
  const { theme } = useTheme();
  let timeoutTimer;
  watch(hasYandexMetrikaConfig, (newValue) => {
    YM.setDefaultOptions({
      collectQueues: newValue
    });
    if (newValue) {
      timeoutTimer = Timer.setTimeout(() => {
        void registerYandexMetrika(yandexMetrikaConfig.value);
        const platform = process.env.VUE_APP_PLATFORM;
        const layout = process.env.VUE_APP_LAYOUT;
        const version = process.env.VUE_APP_VERSION;
        assert(platform, "VUE_APP_PLATFORM env must be present");
        assert(layout, "VUE_APP_LAYOUT env must be present");
        if (login.value) {
          const currentTheme = theme.value;
          analytics.push(Events.Z_INIT, {
            userId: login.value,
            country: countryName.value,
            customerType: vipStatus.value || "normal",
            type: {
              [platform]: `${layout}`
            },
            webVersion: version,
            ...currentTheme ? { mode: currentTheme } : {}
          });
        } else {
          analytics.push(Events.Z_INIT, {
            type: {
              [platform]: `${layout}`
            },
            webVersion: version
          });
        }
      }, initTimeout2);
    } else {
      if (timeoutTimer) {
        clearTimeout(timeoutTimer);
      }
      YM.setDefaultOptions({
        collectQueues: false
      });
      YM.unregister();
    }
  }, {
    immediate: true
  });
}
function initZetaDSP() {
  const metricsStore = useMetricsStore();
  const zetaDSPisEnabled = toRef(metricsStore, "zetaDSPisEnabled");
  const zetaDSPMainType = toRef(metricsStore, "zetaDSPMainType");
  const zetaDspAll = toRef(metricsStore, "zetaDspAll");
  const zetaDspDeposit = toRef(metricsStore, "zetaDspDeposit");
  const zetaDspFTD = toRef(metricsStore, "zetaDspFTD");
  const zetaDspRegistration = toRef(metricsStore, "zetaDspRegistration");
  const zetaDspLanding = toRef(metricsStore, "zetaDspLanding");
  let script;
  const unwatch = watch(zetaDSPisEnabled, (newValue) => {
    if (newValue) {
      const mainType = zetaDSPMainType.value;
      if (script === void 0 && (mainType == null ? void 0 : mainType.params.length)) {
        const parameters = getObjectFromPairs(mainType.params);
        if (parameters.src) {
          script = new ScriptElement({
            async: true,
            src: parameters.src
          });
          void whenClientNetworkIdle({ interval: 300 }).then(() => {
            script.addScript().then(() => {
              const zetaDspService = ZetaDspService.getInstance();
              zetaDspService.setDefaultOptions({
                [zetaDcpConfigTypeAll]: getObjectFromPairs(zetaDspAll.value),
                [zetaDcpConfigTypeDeposit]: getObjectFromPairs(zetaDspDeposit.value),
                [zetaDcpConfigTypeFirstTimeDeposit]: getObjectFromPairs(zetaDspFTD.value),
                [zetaDcpConfigTypeRegistration]: getObjectFromPairs(zetaDspRegistration.value)
              });
              zetaDspService.setArgsAndTrack(
                getObjectFromPairs(zetaDspLanding.value)
              );
              unwatch();
            }, (error) => {
              console.warn(`Script append error: ${error}`);
            });
          });
        }
      }
    }
  }, {
    immediate: true
  });
}
const initTimeout = /* @__PURE__ */ (() => process.env.VUE_APP_LAYOUT_DESKTOP)() ? 4e3 : 6e3;
const AnalyticsPlugin = {
  install(app) {
    app.config.globalProperties.$ymUtils = {
      phoneCall() {
        useAnalytics().phoneCall();
      }
    };
    app.use(CollectClickPlugin);
    const initYandexMetrika = () => {
      initYM({
        initTimeout
      });
    };
    const initZetaDSPTag = () => {
      initZetaDSP();
    };
    const initGM = () => {
      initGoogleAnalytics();
    };
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      initYandexMetrika();
      initGM();
    } else {
      requestIdleCallback(initYandexMetrika);
      requestIdleCallback(initZetaDSPTag, { timeout: initTimeout });
      requestIdleCallback(initGM, { timeout: initTimeout });
    }
  }
};
export {
  AnalyticsPlugin
};
