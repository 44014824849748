import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class QuickBetsModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addPrefetchRoute({
      name: RouteName.FAST_GAMES,
      path: '/fastgames',
      prefetch: () => import('./prefetch/FastGamesRedirectPrefetch'),
    });

    router.addPrefetchRoute({
      name: RouteName.FAST_GAMES_ITEM,
      path: '/fastgames/:gameId',
      prefetch: () => import('./prefetch/FastGamesDetailsRedirectLobbyPrefetch'),
    });

    router.addDefaultRoute({
      name: RouteName.QUICK_BETS,
      path: '/quick-bets/:groupUrl?/:categoryUrl?',
      prefetch: () => import('./prefetch/QuickBetsLobbyPrefetch'),
      component: () => import('./pages/QuickBetsLobbyRoutePage/QuickBetsLobbyRoutePage.vue'),
      seoController: () => import('web/src/modules/egs/submodules/seo/EgsPageSeoController'),
    });

    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      router.addDefaultRoute({
        name: RouteName.QUICK_BETS_DETAILS,
        path: '/quick-bets/:groupUrl/play/:gameUrl',
        prefetch: () => import('./prefetch/QuickBetsDetailsPrefetch'),
        component: () => import('./pages/QuickBetDetailsRoutePage/QuickBetsDetailsRoutePage.vue'),
        seoController: () => import('web/src/modules/egs/submodules/seo/EgsPageSeoController'),
      });
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.addModalRoute({
        name: RouteName.QUICK_BETS_DETAILS,
        path: '/quick-bets/:groupUrl/play/:gameUrl',
        prefetch: () => import('./prefetch/QuickBetsDetailsPrefetch'),
        component: () => import('./pages/QuickBetDetailsRoutePage/QuickBetsDetailsRoutePage.vue'),
        topBar: () => import('web/src/components/Empty/components/VEmpty'),
        seoController: () => import('web/src/modules/egs/submodules/seo/EgsPageSeoController'),
      });
    }
  }
}
