<script lang="ts" setup>
import {
  defineAsyncComponent,
} from 'vue';

import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { useDepositNotification } from 'web/src/modules/front-notifications/components/DepositNotification/composables';
import useSupport from 'web/src/modules/support/composables/useSupport';

import type { AuthorizedAppControllerEmits, AuthorizedAppControllerProps } from './types';
import { useAuthorizedAppController } from './composables';

const props = withDefaults(defineProps<AuthorizedAppControllerProps>(), {});
const emit = defineEmits<AuthorizedAppControllerEmits>();

const EgsLoyaltyProgramLevelDoneModal = '1'

  ? defineAsyncComponent(() => import('web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramLevelDoneModal/CasinoLoyaltyProgramLevelDoneModal.vue'))
  : VEmpty;

const CustomerNotificationsModalController = defineAsyncComponent(

  () => import('web/src/modules/customer-notifications/views/CustomerNotificationsModalController/CustomerNotificationsModalController.vue'),
);
const IdentificationRedirectsController = null

  ? defineAsyncComponent(() => import('web/src/modules/identification/submodules/status/components/IdentificationRedirectsController/IdentificationRedirectsController.vue'))
  : VEmpty;
const TheSupportWidget = defineAsyncComponent(
  () => import('web/src/modules/support/submodules/widget/views/TheSupportWidget/TheSupportWidget.vue'),
);

if (null) {
  useDepositNotification();
}

const {
  isEgsLoyaltyDoneModalVisible,
} = useAuthorizedAppController(props, emit);

const { isLiveChatAvailable, isHelpMode } = useSupport();
</script>

<template>
  <EgsLoyaltyProgramLevelDoneModal
    v-if="'1' && isEgsLoyaltyDoneModalVisible"
  />
  <CustomerNotificationsModalController />
  <IdentificationRedirectsController
    v-if="null"
  />
  <TheSupportWidget
    v-if="'1' && isLiveChatAvailable && isHelpMode"
  />
</template>
