import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class CustomTransactionHistoryModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addProfileRoute({
      name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
      path: '/customer/history',

      component: () => import('./pages/CustomerHistoryListRouteComponent/CustomerHistoryListRouteComponent.vue'),
      title: '{{$t(\'WEB2_TRANSACTION_HISTORY\')}}',
      topBarProps: {
        hidePrefix: !!process.env.VUE_APP_LAYOUT_DESKTOP && !process.env.VUE_APP_FEATURE_DESKTOP_PROFILE_AS_PHONE_ENABLED,
      },
      topBarType: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'secondary' : undefined,
    });

    router.addProfileRoute({
      name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
      path: '/customer/history/transaction/:group/:id/:accountType?',

      component: () => import('./pages/CustomerHistoryDetailsRouteComponent/CustomerHistoryDetailsRouteComponent.vue'),

      topBar: () => import('./layouts/CustomerHistoryTopBarRouteComponent/CustomerHistoryTopBarRouteComponent.vue'),
    });
  }
}
