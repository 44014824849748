import type { EventsBus } from '@leon-hub/event-bus';
import {
  ApiConnectionError,
  ApiRequestAbortedError,
  ApiServiceUnavailableError,
  ApiTechnicalError,
  GqlApiAccessDeniedError,
} from '@leon-hub/api';
import { AccessDeniedRemoteApiExceptionCode } from '@leon-hub/api-sdk';
import { BusEvent } from '@leon-hub/event-bus';

export default function initErrorsInterface(bus: EventsBus): void {
  const emitError = (error: Error) => {
    bus.emit(BusEvent.HANDLE_ERROR, {
      error,
    });
  };

  window.mainApp = window.mainApp || {};
  window.mainApp.errors = {
    apiConnectionError: () => emitError(new ApiConnectionError()),
    apiTechnicalError: () => emitError(new ApiTechnicalError()),
    apiServiceUnavailableError: () => emitError(new ApiServiceUnavailableError()),
    apiRequestAbortedError: () => emitError(new ApiRequestAbortedError()),
    apiAccessDeniedError: () => emitError(new GqlApiAccessDeniedError({
      extensions: {
        errorCode: AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED,
      },
    })),
    apiCustomError: (error?: Error) => emitError(error ?? new Error('Custom public API error')),
  };
}
