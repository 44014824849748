import {
  nextTick,
  onMounted,
} from 'vue';

import { nextAnimationFrame } from '@leon-hub/html-utils';

import { useGraphqlClient } from '@core/app-rest-client';

import { useRecaptchaV3 } from 'web/src/modules/captcha/composables/useRecaptchaV3';

import type {
  GuestAppControllerEmits,
  GuestAppControllerProps,
} from '../types';
import useGuestHandler from './useGuestHandler';
import useGuestRedirect from './useGuestRedirect';

export default function useGuestAppController(
  props: GuestAppControllerProps,
  emit: GuestAppControllerEmits,
): void {
  const api = useGraphqlClient();
  const { onLogout } = useGuestHandler();
  const { redirect: guestRedirect } = useGuestRedirect();
  const { initRecaptchaV3 } = useRecaptchaV3();

  onMounted(async () => {
    try {
      await nextTick();
      await nextAnimationFrame();
      await Promise.all([
        onLogout(props.isAppInit),
      ].concat(props.isAppInit ? [api.flush()] : []));
    } catch (error) {
      emit('load-error', error);
    }

    emit('loaded');

    if (props.isAppInit) {
      guestRedirect();
    }
    initRecaptchaV3();
  });
}
