import { useAuthStore } from '@core/auth';
import { useCustomerDataStore } from '@core/customer';
import { useUserStore } from '@core/user';

export default function initCustomerAppInterface(): void {
  const customerDataStore = useCustomerDataStore();
  const userStore = useUserStore();
  const authStore = useAuthStore();

  window.mainApp = window.mainApp || {};
  window.mainApp.user = {
    getLogin() {
      return customerDataStore.login;
    },
    isLoggedIn() {
      return authStore.isLoggedIn;
    },
    async logout() {
      return userStore.logout();
    },
    async forgotSession() {
      useAuthStore().forgotSession();
      return Promise.resolve();
    },
  };
}
