import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

import { addCmsRoute } from 'web/src/modules/cms/CmsModule';

export class PromotionsModule extends AppModule {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  addRoutes(router: AppVueRouter): void {
    const titlePromotionsPage = '{{$t(\'WEB2_PROMOTIONS_AND_BONUSES\')}}';
    const pathPromotionsPage = '/promo/:categoryId?';

    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      router.addDefaultRoute({
        name: RouteName.PROMOTIONS,
        path: pathPromotionsPage,
        isRightSidebarHidden: !!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED,
        component: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? () => import('./pages/SPromotionsRoutePage/SPromotionsRoutePage.vue')
          : () => import('./pages/PromotionsRoutePage/PromotionsRoutePage.vue'),

        leftSideBar: () => import('./layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
      });

      router.addDefaultRoute({
        name: RouteName.PROMOTION_DETAILS,
        path: '/promo/:categoryId/:actionUrl',

        component: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? () => import('./pages/PromotionDetailsRoutePage/SPromotionDetailsRoutePage.vue')
          : () => import('./pages/PromotionDetailsRoutePage/PromotionDetailsRoutePage.vue'),

        leftSideBar: () => import('./layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
      });

      router.addDefaultRoute({
        name: RouteName.BONUS_WALLET,
        path: '/bonus-wallet',
        component: () => import('./pages/BonusWalletRoutePage/BonusWalletRoutePage.vue'),

        leftSideBar: () => import('./layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
      });

      if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
        router.addDefaultRoute({
          name: RouteName.TOURNAMENTS,
          path: '/tournaments/:categoryId?',
          isRightSidebarHidden: true,
          component: () => import('./pages/SPromotionsRoutePage/SPromotionsRoutePage.vue'),

          leftSideBar: () => import('./layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
        });

        router.addDefaultRoute({
          name: RouteName.TOURNAMENT_DETAILS,
          path: '/tournaments/:categoryId/:actionUrl',

          component: () => import('./pages/PromotionDetailsRoutePage/SPromotionDetailsRoutePage.vue'),

          leftSideBar: () => import('./layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
        });
      }
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.addModalRoute({
        name: RouteName.PROMOTION_DETAILS,
        path: '/promo/:categoryId/:actionUrl',
        topBar: () => import('./layout/PromotionDetailsTopBarRouteComponent.vue'),

        component: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? () => import('./pages/PromotionDetailsRoutePage/SPromotionDetailsRoutePage.vue')
          : () => import('./pages/PromotionDetailsRoutePage/PromotionDetailsRoutePage.vue'),
        isFooterVisible: true,
      });

      router.addModalRoute({
        name: RouteName.BONUS_WALLET,
        path: '/bonus-wallet',
        title: titlePromotionsPage,
        component: () => import('./pages/BonusWalletRoutePage/BonusWalletRoutePage.vue'),
        navigation: () => import('./layout/PromotionsTabsRouteComponent.vue'),
        isFooterVisible: true,
      });

      if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
        router.addDefaultRoute({
          name: RouteName.PROMOTIONS,
          path: pathPromotionsPage,
          leftSideBar: undefined,
          component: () => import('./pages/SPromotionsRoutePage/SPromotionsRoutePage.vue'),
          navigation: () => import('./layout/PromotionsTabsRouteComponent.vue'),
        });

        router.addDefaultRoute({
          name: RouteName.TOURNAMENTS,
          path: '/tournaments/:categoryId?',
          component: () => import('./pages/SPromotionsRoutePage/SPromotionsRoutePage.vue'),
          navigation: () => import('./layout/PromotionsTabsRouteComponent.vue'),
        });

        router.addModalRoute({
          name: RouteName.TOURNAMENT_DETAILS,
          path: '/tournaments/:categoryId/:actionUrl',
          topBar: () => import('./layout/PromotionDetailsTopBarRouteComponent.vue'),

          component: () => import('./pages/PromotionDetailsRoutePage/SPromotionDetailsRoutePage.vue'),
          isFooterVisible: true,
        });
      } else {
        router.addModalRoute({
          name: RouteName.PROMOTIONS,
          path: pathPromotionsPage,
          title: titlePromotionsPage,
          component: () => import('./pages/PromotionsRoutePage/PromotionsRoutePage.vue'),
          navigation: () => import('./layout/PromotionsTabsRouteComponent.vue'),
          isFooterVisible: true,
        });
      }
    }

    addCmsRoute(router, {
      name: RouteName.CMS_PROMO_TERMS,
      path: '/promoterms/:cmsKey',
      customComponent: () => import('./pages/PromotionsTermsModalPage/PromotionsTermsModalPage.vue'),
      cmsKey: '',
      title: '{{$t(\'WEB2_PROMO_TERMS_TITLE\')}}',
    }, ModalDesktopPreset.LargeDesktopModal);
  }
}
