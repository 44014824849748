import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';

interface RedirectNavigationGuard {
  redirectUri: string;
}

export default function create301RedirectNavigationGuard(
  router: AppVueRouter,
  { redirectUri }: RedirectNavigationGuard,
): void {
  if (process.env.VUE_APP_PLATFORM_WEB && process.env.VUE_APP_PRERENDER && redirectUri) {
    let localRedirectUri = redirectUri;
    router.beforeEach(() => {
      if (localRedirectUri) {
        localRedirectUri = '';
        return {
          name: RouteName.REDIRECT_SEO,
          state: {
            redirectCode: '301',
            redirectLocation: redirectUri,
          },
        };
      }
    });
  }
}
