import { useEventsBus } from '@leon-hub/event-bus';

import type { AppVueRouter } from '@core/router';

import useCordovaPushNotificationsStore
  from 'web/src/modules/push-notifications/store/useCordovaPushNotificationsStore';

export default async function setupRouterLoadedEvents(router: AppVueRouter): Promise<void> {
  const bus = useEventsBus();
  if (!process.env.VUE_APP_BUNDLER_NUXT) {
    await router.isReady();
  }
  bus.initialize();

  // Execute after router prepared for BG messages links handler
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    void useCordovaPushNotificationsStore().updateToken();
  }
}
