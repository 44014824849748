import { isOptionalBoolean } from '@leon-hub/guards';

import {
  LEXIS_NEXIS_STORAGE_NAMESPACE_PROFILED,
} from '../constants';
import { LexisNexisProfiledStorage } from '../services';

export function getLexisNexisProfiledStorage (customerLogin: string): LexisNexisProfiledStorage {
  return new LexisNexisProfiledStorage({
    id: `lex-nex:${customerLogin}:${LEXIS_NEXIS_STORAGE_NAMESPACE_PROFILED}`,
    guard: isOptionalBoolean,
  })
}
