<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

import {
  IconName,
} from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { ModalDesktopPreset } from '@core/router';

import { SButton } from '@components/buttons';
import { VScrollbar } from '@components/v-scrollbar';

import VOverlay from 'web/src/components/Modal/VOverlay/VOverlay.vue';
import useDesktopModalHeight from 'web/src/modules/core/components/DesktopModal/composables/useDesktopModalHeight';
import {
  ModalHeight,
  ModalWidth,
} from 'web/src/modules/core/components/DesktopModal/enums';

import { useKeepAliveComponents } from '../composables/useKeepAliveComponents';
import {
  useDesktopModal,
  useDesktopModalComponents,
  useDesktopModalScroll,
} from './composables';

const LeftSideBar = defineAsyncComponent(
  () => import('web/src/modules/profile/layouts/UserProfileSidebarRouteComponent/UserProfileSidebarRouteComponent.vue'),
);
const LeftSideTopBar = defineAsyncComponent(
  () => import('web/src/modules/profile/components/SidebarProfileInfo/SidebarProfileInfoRouteComponent.vue'),
);

const {
  isNoMinHeightLimit,
  desktopModal,
  modalWidth,
  modalHeight,
  modalPreset,
  hasInnerModal,
  showSideBar,
  currentRouteName,
  onOverlayClicked,
  onInnerScroll,
  closeModal,
} = useDesktopModal();
const {
  DefaultComponent,
  Navigation,
  defaultProps,
  TopBar,
  topBarProps,
} = useDesktopModalComponents();
const {
  scrollbar,
  recomposeScroll,
} = useDesktopModalScroll();

const {
  sidebar,
  contentStyles,
} = useDesktopModalHeight();

const {
  keepAliveIncludes,
} = useKeepAliveComponents();
</script>

<template>
  <div
    v-if="desktopModal"
    id="outerModalContainer"
    v-data-test="{
      el: 'desktop-modal-wrapper',
      route: currentRouteName,
    }"
    :class="$style['desktop-modal__wrapper']"
  >
    <div
      :class="$style['desktop-modal__radius']"
    >
      <div
        :class="{
          [$style['desktop-modal']]: true,
          [$style['desktop-modal--no-min-height']]: isNoMinHeightLimit,
          [$style[`desktop-modal--width--responsible`]]: null,
          [$style[`desktop-modal--width-${modalWidth}`]]: modalWidth !== ModalWidth.DEFAULT && !null,
          [$style[`desktop-modal--height-${modalHeight}`]]: modalHeight !== ModalHeight.DEFAULT,
          [$style['desktop-modal--profile']]: !null
            && modalPreset === ModalDesktopPreset.ProfileDesktopModal,
          [$style['desktop-modal--with-inner-modal']]: hasInnerModal,
        }"
      >
        <div
          v-if="!null && showSideBar"
          v-data-test="{ el: 'desktop-modal-sidebar' }"
          :class="$style['desktop-modal__sidebar']"
        >
          <div
            ref="sidebar"
          >
            <LeftSideTopBar />
            <LeftSideBar />
          </div>
        </div>
        <div
          id="desktop-modal"
          :class="$style['desktop-modal__content']"
          :style="contentStyles"
        >
          <KeepAlive :include="keepAliveIncludes">
            <component
              :is="TopBar"
              v-bind="topBarProps"
            />
          </KeepAlive>
          <div
            :class="{
              [$style['desktop-modal__scrollbar']]: true,
              [$style['desktop-modal__scrollbar--profile']]: currentRouteName === RouteName.PROFILE,
            }"
          >
            <VScrollbar
              ref="scrollbar"
              flex-fill
              test-el="modal"
              use-scroll-listener
              @scroll="onInnerScroll"
            >
              <KeepAlive :include="keepAliveIncludes">
                <component
                  :is="Navigation"
                  v-if="Navigation"
                  @vue:mounted="recomposeScroll"
                />
              </KeepAlive>
              <KeepAlive :include="keepAliveIncludes">
                <component
                  :is="DefaultComponent"
                  v-bind="defaultProps"
                  @vue:mounted="recomposeScroll"
                />
              </KeepAlive>
            </VScrollbar>
          </div>
        </div>
      </div>
      <div
        v-if="null"
        :class="$style['desktop-modal__close']"
      >
        <SButton
          :icon-name="IconName.CROSS"
          :disabled="hasInnerModal"
          kind="quaternary-secondary"
          size="xsmall"
          @click="closeModal"
        />
      </div>
    </div>
  </div>
  <VOverlay
    v-if="desktopModal"
    :class="$style['desktop-modal__overlay']"
    @click="onOverlayClicked"
  />
</template>

<style lang="scss" module>
.desktop-modal {
  $self: &;

  @include z-index(base);
  @include widthModal;

  display: flex;
  min-height: $desktopModalMinHeight;
  max-height: calc(100vh - #{$desktopModalPaddingFromBottom});
  overflow: hidden;
  pointer-events: all;
  border-radius: $desktopModalBorderRadius;
  box-shadow: 0 8px 16px var(--ModalShadow);
  will-change: transform;

  &--no-min-height {
    min-height: 40px;
  }

  &__radius {
    position: relative;
    display: flex;
    border-radius: $desktopModalBorderRadius;
  }

  &__sidebar {
    min-width: 252px;
    max-width: 252px;
    background-color: $desktopModalBackground;

    & + #{$self}__content {
      max-width: calc(100% - 252px);
    }
  }

  &__content {
    @include desktopModalContent;

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__scrollbar {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    background-color: $desktopModalBackground;
  }

  &__wrapper {
    @include z-index(modal);

    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: $desktopModalPaddingFromTop;
    pointer-events: none;
  }

  &--width {
    &-medium {
      width: 480px;
    }

    &-medium-plus {
      width: 540px;
    }

    &-big {
      width: 627px;
    }

    &-large {
      width: 720px;
    }
  }

  &--height {
    &-large {
      height: 600px;
    }

    &-extra {
      height: 584px;
    }
  }

  &--with-inner-modal {
    min-height: $desktopModalMinHeightWithInnerModal;
  }

  &__overlay {
    z-index: z-index(overlay-transition);
  }

  &__close {
    position: absolute;
    bottom: 100%;
    left: 100%;
    z-index: z-index(base);
    pointer-events: all;
  }
}
</style>
