import type { Ref } from 'vue';
import noop from 'lodash/noop';
import {
  computed,
  nextTick,
  ref,
  toRef,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import type { BaseRouteNameType, SharedRouteNameType } from '@leon-hub/routing-config-names';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { IconName, IconSize } from '@leon-hub/icons';
import { CasinoRouteName, isEGSRoute, RouteName } from '@leon-hub/routing-config-names';

import { useIsLoggedIn } from '@core/auth';
import { useI18n, useI18nLanguage } from '@core/i18n';
import { useCustomerFinanceStore } from '@core/money';
import { useNavigationItemsStore, useRootNavigationStore } from '@core/navigation';
import { useRootStore } from '@core/root';
import { useRouterStore } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';
import { useThemeStore } from '@core/theme';

import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind } from '@components/buttons';

import { LanguageSelectorPlace } from '@modules/localization-utilities';

import {
  navigateToLocationsWithCallbacks,
  resolveHeaderBarsSportlinePageLink,
} from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';

export interface HeaderBarRouteComponentComposable {
  menuOverflowHidden: Ref<boolean>;
  menuButtonProperties: VButtonProps;
  toggleSidebar(): void;
  brandName: Ref<string>;
  isLoggedIn: Ref<boolean>;
  topNavigationItems: Ref<NavigationItem[]>;
  formattedBalance: Ref<string>;
  isPromotionsHighlightLinkEnabled: Ref<boolean>;
  isLandingFestivalEnabled: Ref<boolean>;
  handleSportlineNavigation(to: BaseRouteNameType): void;
  setMenuOverflowVisible(isVisible: boolean): void;
  searchButtonProps: VButtonProps;
  onMouseOverSearch(): void;
  searchClick(): void;
  isLanguageSwitcherAvailable: Ref<boolean>;
  currentLanguageFlag: Ref<string>;
  isSidebarToggleVisible: Ref<boolean>;
  openLanguage(): void;
  isThemeSwitcherAvailable: Ref<boolean>;
  toggleTheme(): void;
  isSimplifiedLogo: Ref<boolean>;
  hasCustomTheme: Ref<boolean>;
}

export default function useHeaderBarRouteComponent(): HeaderBarRouteComponentComposable {
  const { $translate } = useI18n();
  const { setLanguageSelectorPlace } = useI18nLanguage();
  const { isLoggedIn } = useIsLoggedIn();
  const bus = useEventsBus();
  const router = useRouter();
  const route = useRoute();

  const siteConfigStore = useSiteConfigStore();
  const customerFinanceStore = useCustomerFinanceStore();
  const rootStore = useRootStore();
  const navigationItemsStore = useNavigationItemsStore();

  const formattedBalance = toRef(customerFinanceStore, 'formattedBalance');
  const isThemeSwitcherAvailable = toRef(siteConfigStore, 'isThemeSwitcherEnabled');
  const isPromotionsHighlightLinkEnabled = toRef(siteConfigStore, 'isPromotionsHighlightLinkEnabled');
  const isLandingFestivalEnabled = toRef(siteConfigStore, 'isLandingFestivalEnabled');
  const currentLanguageFlag = toRef(rootStore, 'currentLanguageFlag');
  const isLanguageSwitcherAvailable = toRef(rootStore, 'isLanguageSwitcherAvailable');
  const isSidebarToggleVisible = computed(() => !route.meta.isLeftSidebarToggleHidden);
  const topNavigationItems = toRef(navigationItemsStore, 'topNavigationItems');
  const isEGSHomePageType = toRef(useRouterStore(), 'isEGSHomePageType');
  const hasCustomTheme = toRef(useThemeStore(), 'hasCustomTheme');

  const menuButtonProperties: VButtonProps = {
    kind: process.env.VUE_APP_FEATURE_DARK_HEADER_ENABLED
      ? ButtonKind.TRANSPARENT_DARK_VERSION
      : ButtonKind.TRANSPARENT,
    iconSize: IconSize.SIZE_24,
    iconName: IconName.HAMBURGER,
    height: ButtonHeight.LARGE,
  };

  const searchButtonProps: VButtonProps = {
    iconName: IconName.SEARCH,
    iconSize: IconSize.SIZE_20,
    height: ButtonHeight.SMALL,
    kind: process.env.VUE_APP_FEATURE_DARK_HEADER_ENABLED ? ButtonKind.HEADER_DARK_VERSION : ButtonKind.HEADER,
    rounded: true,
  };

  const brandName = $translate('WEB2_BRAND_NAME_SHORT');

  const menuOverflowHidden = ref(true);

  const isSimplifiedLogo = computed<boolean>(
    () => Boolean(process.env.VUE_APP_FEATURE_ASO_DISABLED) && isLoggedIn.value,
  );

  function toggleTheme(): void {
    useThemeStore().toggleTheme();
  }

  function toggleSidebar(): void {
    bus.emit(BusEvent.SIDE_MENU_TOGGLE, {});
  }

  function handleSportlineNavigation(to: BaseRouteNameType): void {
    const { persistLocation } = useSportlinePersistLocation();
    const location = resolveHeaderBarsSportlinePageLink(to, persistLocation.value);

    void navigateToLocationsWithCallbacks(router, location, {
      beforeNavigation: async () => {
        const navigationStore = useRootNavigationStore();

        if (navigationStore.isRouteContentLoading[to] !== undefined) {
          return;
        }

        navigationStore.setOnlyFirstRouteContentLoading(to);
        await nextAnimationFrame();
        await nextTick();
        await nextAnimationFrame();
      },
    });
  }

  function searchClick(): void {
    let searchRoute: SharedRouteNameType;
    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      searchRoute = isEGSRoute(route.name, isEGSHomePageType.value)
        ? CasinoRouteName.CASINO_SEARCH
        : RouteName.SEARCH;
    } else {
      searchRoute = RouteName.SEARCH;
    }

    void router.push({ name: searchRoute });
  }

  function onMouseOverSearch(): void {
    import('web/src/modules/search/pages/SearchRoutePage/SearchRoutePage.vue').catch(noop);
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      import('web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue').catch(noop);
    }
  }

  function openLanguage(): void {
    if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      setLanguageSelectorPlace(LanguageSelectorPlace.HEADER);
      void router.push({
        name: RouteName.LANGUAGE,
      });
    }
  }

  function setMenuOverflowVisible(isVisible: boolean): void {
    menuOverflowHidden.value = !isVisible;
  }

  return {
    menuOverflowHidden,
    menuButtonProperties,
    isSidebarToggleVisible,
    toggleSidebar,
    brandName,
    isLoggedIn,
    topNavigationItems,
    formattedBalance,
    isPromotionsHighlightLinkEnabled,
    isLandingFestivalEnabled,
    handleSportlineNavigation,
    setMenuOverflowVisible,
    searchButtonProps,
    onMouseOverSearch,
    searchClick,
    isLanguageSwitcherAvailable,
    currentLanguageFlag,
    openLanguage,
    isThemeSwitcherAvailable,
    toggleTheme,
    isSimplifiedLogo,
    hasCustomTheme,
  };
}
