import type { RouteLocationNormalized } from 'vue-router';

import type { AppVueRouter } from '@core/router';

import type { RouterHistoryState } from 'web/src/modules/core/types';

function processNotPopstateState(
  to: RouteLocationNormalized,
  router: AppVueRouter,
  lastState?: RouterHistoryState,
): void {
  const newUrl = router.resolve(to).href;
  const lastRoutePosition = lastState?.realPosition || lastState?.position || 0;

  const newHistoryState = {
    ...window.history.state,
    back: router.isReplaced() ? window.history.state?.back : window.history.state?.current,
    current: to.fullPath,
    forward: null,
    // For backward compat with router
    position: lastState?.position,
    realPosition: router.isReplaced() ? lastRoutePosition : lastRoutePosition + 1,
    replaced: router.isReplaced(),
    meta: {
      isModalRoute: true,
      isBackButton: router.isReplaced()
        ? !!window.history.state?.prevMeta?.isModalRoute
        : !!lastState?.meta?.isModalRoute,
    },
    prevMeta: router.isReplaced()
      ? window.history.state?.prevMeta
      : window.history.state?.meta,
  };

  if (newHistoryState.replaced) {
    window.history.replaceState(newHistoryState, '', newUrl);
  } else {
    window.history.pushState(newHistoryState, '', newUrl);
  }
}

export default function processDesktopHistoryState(
  to: RouteLocationNormalized,
  router: AppVueRouter,
  lastState?: RouterHistoryState,
): void {
  if (process.env.VUE_APP_LAYOUT_DESKTOP && to.meta.modalPreset) {
    const newUrl = router.resolve(to).href;

    if (!router.isPopStateDetected()) {
      processNotPopstateState(to, router, lastState);
    } else {
      window.history.replaceState({
        ...window.history.state,
        current: to.fullPath,
        forward: window.history.state?.forward || lastState?.current,
      }, '', newUrl);
    }
  }
}
