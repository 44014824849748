import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class ResponsibleGamblingModuleOld extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addProfileRoute({
      name: RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS,
      path: '/profile/settings/responsible-gambling-deposit-limits',
      component: () => import('./pages/ResponsibleGamblingDepositLimitsRoutePage/ResponsibleGamblingDepositLimitsRoutePage.vue'),
      title: '{{$t(\'WEB2_RESTRICT_ACCOUNT_DEPOSIT\')}}',
    });

    router.addProfileRoute({
      name: RouteName.RESPONSIBLE_GAMBLING_BETTING_LIMITS,
      path: '/profile/settings/responsible-gambling-betting-limits',
      component: () => import('./pages/ResponsibleGamblingBettingLimitsRoutePage/ResponsibleGamblingBettingLimitsRoutePage.vue'),
      title: '{{$t(\'WEB2_LIMIT_AMOUNT_OF_BETS\')}}',
    });

    router.addProfileRoute({
      name: RouteName.RESPONSIBLE_GAMBLING_SELF_EXCLUSION,
      path: '/profile/settings/responsible-gambling-self-exclusion',
      component: () => import('./pages/ResponsibleGamblingSelfExclusionRoutePage/ResponsibleGamblingSelfExclusionRoutePage.vue'),
      title: '{{$t(\'WEB2_SELFEXCLUSION_FOR_SIX_MONTH\')}}',
    });

    router.addProfileRoute({
      name: RouteName.RESPONSIBLE_GAMBLING_COMPREHENSIVE_SELF_EXCLUSION,
      path: '/profile/settings/responsible-gambling-comprehensive-self-exclusion',
      component: () => import('./pages/ResponsibleGamblingComprehensiveSelfExclusionRoutePage/ResponsibleGamblingComprehensiveSelfExclusionRoutePage.vue'),
      title: '{{$t(\'WEB2_COMPLEX_SELFEXCLUSION\')}}',
    });

    router.addProfileRoute({
      name: RouteName.RESPONSIBLE_GAMBLING_PARTIAL_RESTRICTION,
      path: '/profile/settings/responsible-gambling-partial-restriction',
      component: () => import('./pages/ResponsibleGamblingPartialRestrictionRoutePage/ResponsibleGamblingPartialRestrictionRoutePage.vue'),
      title: '{{$t(\'WEB2_PARTIAL_RESTRICTION_SMALL\')}}',
    });
  }
}
