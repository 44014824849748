import { PaymentType } from '@leon-hub/api-sdk';
import { isInFrame } from '@leon-hub/html-utils';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

import { PaymentsPage } from 'web/src/modules/core/services/router/enums';

const depositTitle = '{{$t(\'WEB2_DEPOSIT_FUNDS\')}}';
const depositOkPath = '/depositok';
const depositProcessPath = '/depositprocess';
const depositFailPath = '/depositfail';
const withdrawalOkPath = '/withdrawalok';

export class PaymentsModule extends AppModule {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  addRoutes(router: AppVueRouter): void {
    router.addProfileRoute({
      name: RouteName.DEPOSITS,
      path: '/deposits',
      component: () => import('web/src/modules/payments/submodules/deposits/pages/PaymentsDepositsRoutePage.vue'),
      topBar: () => import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
      navigation: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? () => import('web/src/modules/payments/components/PaymentsNavigationTabs/PaymentsNavigationTabs.vue')
        : undefined,
      topBarProps: {
        page: process.env.VUE_APP_LAYOUT_PHONE ? PaymentsPage.MOBILE_DEPOSITS : PaymentsPage.DESKTOP_DEPOSITS,
        paymentType: PaymentType.DEPOSIT,
      },
    });

    router.addProfileRoute({
      name: RouteName.DEPOSITS_OVERVIEW,
      path: '/topup',
      component: () => import('web/src/modules/payments/submodules/deposits/pages/PaymentsDepositsRoutePage.vue'),
      topBar: () => import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
      topBarProps: {
        page: process.env.VUE_APP_LAYOUT_PHONE ? PaymentsPage.MOBILE_DEPOSITS : PaymentsPage.DESKTOP_DEPOSITS,
      },
    });

    router.addProfileRoute({
      name: RouteName.DEPOSITS_DETAILS,
      path: '/deposits/:name',
      component: () => import('web/src/modules/payments/submodules/deposits/pages/PaymentsDepositsDetailsRoutePage.vue'),
      prefetch: () => import('web/src/modules/payments/submodules/deposits/prefetch/PaymentsDepositsDetailsPrefetch'),
      topBar: () => import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
      topBarProps: {
        page: PaymentsPage.DEPOSIT_DETAILS,
        paymentType: PaymentType.DEPOSIT,
      },
    });

    router.addProfileRoute({
      name: RouteName.WITHDRAWALS,
      path: '/withdrawals',
      component: () => import('web/src/modules/payments/submodules/withdrawals/pages/PaymentsWithdrawalsRoutePage.vue'),
      topBar: () => import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
      navigation: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? () => import('web/src/modules/payments/components/PaymentsNavigationTabs/PaymentsNavigationTabs.vue')
        : undefined,
      topBarProps: {

        page: process.env.VUE_APP_LAYOUT_PHONE ? PaymentsPage.MOBILE_WITHDRAWALS : PaymentsPage.DESKTOP_WITHDRAWALS,
        paymentType: PaymentType.WITHDRAWAL,
      },
    });

    router.addProfileRoute({
      name: RouteName.WITHDRAWALS_DETAILS,
      path: '/withdrawals/:name',

      component: () => import('web/src/modules/payments/submodules/withdrawals/pages/PaymentsWithdrawalsDetailsRoutePage.vue'),

      prefetch: () => import('web/src/modules/payments/submodules/withdrawals/prefetch/PaymentsWithdrawalsDetailsPrefetch'),
      topBar: () => import('web/src/modules/payments/layouts/PaymentsTopBarRouteComponent.vue'),
      topBarProps: {
        page: PaymentsPage.WITHDRAWAL_DETAILS,
        paymentType: PaymentType.WITHDRAWAL,
      },
    });

    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      if (isInFrame()) {
        router.addBlankRoute({
          name: RouteName.DEPOSITSOK,
          path: depositOkPath,
          component: () => import('web/src/modules/payments/pages/PaymentsDepositsokRoutePage/PaymentsDepositsokRoutePage.vue'),
        });
      } else {
        router.addModalRoute({
          name: RouteName.DEPOSITSOK,
          path: depositOkPath,
          modalPreset: ModalDesktopPreset.DepositDetailsDesktopModal,
          component: () => import('web/src/modules/payments/pages/PaymentsDepositsokRoutePage/PaymentsDepositsokRoutePage.vue'),
          title: depositTitle,
        });
      }

      router.addBlankRoute({
        name: RouteName.DEPOSITSOK2,
        path: '/depositok2',
        component: () => import('web/src/modules/payments/pages/PaymentsDepositsokRoutePage/PaymentsDepositsokRoutePage.vue'),
        props: {
          closeWindow: true,
        },
      });

      if (isInFrame()) {
        router.addBlankRoute({
          name: RouteName.DEPOSITSPROCESS,
          path: depositProcessPath,
          component: () => import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue'),
        });
      } else {
        router.addModalRoute({
          name: RouteName.DEPOSITSPROCESS,
          path: depositProcessPath,
          modalPreset: ModalDesktopPreset.DepositDetailsDesktopModal,
          component: () => import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue'),
          title: depositTitle,
        });
      }

      router.addBlankRoute({
        name: RouteName.DEPOSITSPROCESS2,
        path: '/depositprocess2',
        component: () => import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue'),
        props: {
          closeWindow: true,
        },
      });

      if (isInFrame()) {
        router.addBlankRoute({
          name: RouteName.DEPOSITSFAIL,
          path: depositFailPath,
          component: () => import('web/src/modules/payments/pages/PaymentsDepositsfailRoutePage/PaymentsDepositsfailRoutePage.vue'),
        });
      } else {
        router.addModalRoute({
          name: RouteName.DEPOSITSFAIL,
          path: depositFailPath,
          modalPreset: ModalDesktopPreset.DepositDetailsDesktopModal,
          component: () => import('web/src/modules/payments/pages/PaymentsDepositsfailRoutePage/PaymentsDepositsfailRoutePage.vue'),
          title: depositTitle,
        });
      }

      router.addBlankRoute({
        name: RouteName.DEPOSITSFAIL2,
        path: '/depositfail2',
        component: () => import('web/src/modules/payments/pages/PaymentsDepositsfailRoutePage/PaymentsDepositsfailRoutePage.vue'),
        props: {
          closeWindow: true,
        },
      });

      if (isInFrame()) {
        router.addBlankRoute({
          name: RouteName.WITHDRAWALOK,
          path: withdrawalOkPath,
          component: () => import('web/src/modules/payments/components/PaymentsWithdrawalsokRoutePage/PaymentsWithdrawalsokRoutePage.vue'),
        });
      } else {
        router.addModalRoute({
          name: RouteName.WITHDRAWALOK,
          path: withdrawalOkPath,
          modalPreset: ModalDesktopPreset.DepositDetailsDesktopModal,
          component: () => import('web/src/modules/payments/components/PaymentsWithdrawalsokRoutePage/PaymentsWithdrawalsokRoutePage.vue'),
          title: '{{$t(\'WEB2_WITHDRAWAL_FROM_ACCOUNT_OK\')}}',
        });
      }

      router.addBlankRoute({
        name: RouteName.WITHDRAWALOK2,
        path: '/withdrawalok2',
        component: () => import('web/src/modules/payments/components/PaymentsWithdrawalsokRoutePage/PaymentsWithdrawalsokRoutePage.vue'),
        props: {
          closeWindow: true,
        },
      });
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.addModalRoute({
        name: RouteName.DEPOSITSOK,
        path: depositOkPath,
        component: () => import('web/src/modules/payments/pages/PaymentsDepositsokRoutePage/PaymentsDepositsokRoutePage.vue'),
        title: depositTitle,
      });

      router.addModalRoute({
        name: RouteName.DEPOSITSOK2,
        path: '/depositok2',
        component: () => import('web/src/modules/payments/pages/PaymentsDepositsokRoutePage/PaymentsDepositsokRoutePage.vue'),
        props: {
          closeWindow: true,
        },
      });

      if (process.env.VUE_APP_LAYOUT_PHONE && isInFrame()) {
        router.addBlankRoute({
          name: RouteName.DEPOSITSPROCESS,
          path: depositProcessPath,
          component: () => import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue'),
        });
      } else {
        router.addModalRoute({
          name: RouteName.DEPOSITSPROCESS,
          path: depositProcessPath,
          component: () => import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue'),
          title: depositTitle,
        });
      }

      router.addModalRoute({
        name: RouteName.DEPOSITSPROCESS2,
        path: '/depositprocess2',
        component: () => import('web/src/modules/payments/pages/PaymentsDepositsProcessRoutePage/PaymentsDepositsProcessRoutePage.vue'),
        props: {
          closeWindow: true,
        },
      });

      router.addModalRoute({
        name: RouteName.DEPOSITSFAIL,
        path: depositFailPath,
        component: () => import('web/src/modules/payments/pages/PaymentsDepositsfailRoutePage/PaymentsDepositsfailRoutePage.vue'),
        title: depositTitle,
      });

      router.addModalRoute({
        name: RouteName.DEPOSITSFAIL2,
        path: '/depositfail2',
        component: () => import('web/src/modules/payments/pages/PaymentsDepositsfailRoutePage/PaymentsDepositsfailRoutePage.vue'),
        props: {
          closeWindow: true,
        },
      });

      router.addModalRoute({
        name: RouteName.WITHDRAWALOK,
        path: withdrawalOkPath,
        component: () => import('web/src/modules/payments/components/PaymentsWithdrawalsokRoutePage/PaymentsWithdrawalsokRoutePage.vue'),
        title: '{{$t(\'WEB2_WITHDRAWAL_FROM_ACCOUNT_OK\')}}',
      });

      router.addModalRoute({
        name: RouteName.WITHDRAWALOK2,
        path: '/withdrawalok2',
        component: () => import('web/src/modules/payments/components/PaymentsWithdrawalsokRoutePage/PaymentsWithdrawalsokRoutePage.vue'),
        props: {
          closeWindow: true,
        },
      });
    }

    if (process.env.VUE_APP_FEATURE_ADDRESS_CHECK_ENABLED) {
      router.addProfileRoute({
        name: RouteName.WITHDRAWAL_ADDRESS_CHECK,
        path: '/withdrawals/address-check',
        component: () => import('web/src/modules/payments/submodules/withdrawals/pages/PaymentsWithdrawalAddressCheckRoutePage.vue'),
        title: '{{$t(\'WEB2_WITHDRAWALS_ADDRESS_CHECK_DETAILS_LABEL\')}}',
      });
    }
  }
}
