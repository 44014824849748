import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  onMounted,
  ref,
  toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import { hideSplashscreen } from '@leon-hub/cordova';
import { AbstractError } from '@leon-hub/errors';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { isLocalStorageAvailable, useLocalStorageManager } from '@leon-hub/local-storage';
import { RouteName } from '@leon-hub/routing-config-names';
import {
  getViewportDimension,
  requestIdleCallback,
  Timer,
} from '@leon-hub/utils';

import { useIsLoggedIn } from '@core/auth';
import { useRootStore } from '@core/root';
import { useThemeStore } from '@core/theme';

import LayoutBlank from 'web/src/modules/core/components/LayoutBlank/LayoutBlank.vue';
import LayoutDesktopDefault
  from 'web/src/modules/core/components/LayoutDesktopDefault/LayoutDesktopDefault.vue';
import LayoutLanding from 'web/src/modules/core/components/LayoutLanding/LayoutLanding.vue';
import LayoutPhoneDefault
  from 'web/src/modules/core/components/LayoutPhoneDefault/LayoutPhoneDefault.vue';
import { useLayoutInfo } from 'web/src/modules/core/composables';
import { useErrorHandler } from 'web/src/modules/core/composables/errors';
import { useDiagnosticsStore } from 'web/src/modules/diagnostics/store';
import { getErrorAppComponentByError } from 'web/src/modules/errors/utils';
import localeStorageAsoKey from 'web/src/modules/onboarding/utils/localeStorageAsoKey';

import useCordovaStatusBar from './useCordovaStatusBar';

type MainComponent = typeof LayoutLanding;

type ErrorComponent = ReturnType<typeof getErrorAppComponentByError> | undefined;

export interface MainAppComposable {
  errorComponent: Ref<ErrorComponent>;
  mainComponent: Ref<MainComponent>;
  isPostponedControllerVisible: Ref<boolean>;
  isAppInit: Ref<boolean>;
  isAppMainContentLoaded: Ref<boolean>;
  onAppControllerError(error: unknown): void;
  onAppControllerLoaded(): void;
}

export default function useMainApp(): MainAppComposable {
  const localStorageManager = useLocalStorageManager();
  const { isLanding, isBlank } = useLayoutInfo();
  const router = useRouter();

  const rootStore = useRootStore();
  const { recalculateTheme } = useThemeStore();
  const { setAppMainContentLoaded } = rootStore;
  const isAppMainContentLoaded = toRef(rootStore, 'isAppMainContentLoaded');

  if (process.env.VUE_APP_FEATURE_ASO_ENABLED && isLocalStorageAvailable()) {
    const { isLoggedIn } = useIsLoggedIn();
    if (!localStorageManager.getItem(localeStorageAsoKey) && !isLoggedIn.value) {
      void router.push({
        name: RouteName.ONBOARDING,
        params: {
          id: 'aso-main',
        },
      });
    }
  }

  const mainComponent = computed<MainComponent>(() => {
    if (isLanding.value) {
      return LayoutLanding;
    }

    if (isBlank.value) {
      return LayoutBlank;
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      return LayoutPhoneDefault;
    }

    return LayoutDesktopDefault;
  });

  const isAppInit = ref(true);
  const initAppError = ref<unknown>(null);
  const isPostponedControllerVisible = ref(false);

  const errorComponent = computed<ErrorComponent>(() => (
    initAppError.value ? getErrorAppComponentByError(initAppError.value) : undefined
  ));

  async function showContent(): Promise<void> {
    setAppMainContentLoaded();

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      await nextTick();
      await nextAnimationFrame();
      hideSplashscreen();
      Timer.setTimeout(() => {
        void recalculateTheme();
      }, 1000);
    }
  }

  function onAppControllerError(error: unknown): void {
    const isSilent = error instanceof AbstractError && error.silent;
    const { handleError } = useErrorHandler(router);

    if (isAppInit.value && !isSilent) {
      // eslint-disable-next-line no-console
      console.log(`App Controller Error: ${error}`);

      initAppError.value = error;
    } else {
      handleError(error);
    }

    void showContent();
  }

  function onAppControllerLoaded(): void {
    void showContent();
    isAppInit.value = false;

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      useDiagnosticsStore().measureAppLaunch();
    }
  }

  function registerGlobalListeners(): void {
    let dimensions = getViewportDimension();
    document.documentElement.style.setProperty('--viewport-height', `${dimensions.viewportHeight}px`);
    document.documentElement.scrollTo({ top: 0 });

    window.addEventListener('resize', () => {
      const newDimensions = getViewportDimension();
      document.documentElement.style.setProperty('--viewport-height', `${newDimensions.viewportHeight}px`);
      dimensions = newDimensions;
    });
  }

  function initAutofocusRoutes(): void {
    if (process.env.VUE_APP_OS_IOS) {
      Timer.setTimeout(() => {
        requestIdleCallback(() => {
          void Promise.all([

            import('web/src/modules/support/submodules/feedback-legacy/pages/SupportFeedbackLegacyRoutePage/SupportFeedbackLegacyRoutePage.vue'),
            import('web/src/modules/search/pages/SearchRoutePage/SearchRoutePage.vue'),
          ]).then();
        });
      }, 500);
    }
  }

  function init(): void {
    onMounted(() => {
      Timer.setTimeout(() => {
        isPostponedControllerVisible.value = true;
      }, 3000);

      initAutofocusRoutes();
      registerGlobalListeners();
    });
  }

  init();

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    useCordovaStatusBar();
  }

  return {
    errorComponent,
    mainComponent,
    isPostponedControllerVisible,
    isAppInit,
    isAppMainContentLoaded,
    onAppControllerError,
    onAppControllerLoaded,
  };
}
