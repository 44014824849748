import { toRef } from 'vue';

import type { WatchCustomEffectControls } from './watchCustomEffect';
import { useLexisNexisIntegrationStore } from '../store';
import { createActivationEffect } from './createActivationEffect';
import { log } from './log';
import { watchCustomEffect } from './watchCustomEffect';

export function runApiProfile (): void {
  log('starting profiling..');
  const store = useLexisNexisIntegrationStore();

  const isEnabled = toRef(() => store.isEnabled);
  let controls: WatchCustomEffectControls;
  // TODO: create controls when needed with watchCustomEffect helper when needed and provided with watchCustomEffect options
  const getControls: () => WatchCustomEffectControls = () => ({
    stop: () => controls.stop(),
    continueEffect: (...rest) => controls.continueEffect(...rest),
    addEffect: (...rest) => controls.addEffect(...rest),
  });

  controls = watchCustomEffect(createActivationEffect(getControls), {
    detached: true,
    condition: isEnabled,
    id: 'activation',
  });
}
