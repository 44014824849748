<script lang="ts" setup>
import BaseErrorContent from '../BaseErrorContent/BaseErrorContent.vue';
import { useServiceUnavailableError } from './composables';

const {
  errorProps,
} = useServiceUnavailableError();
</script>

<template>
  <BaseErrorContent v-auto-id="'ServiceUnavailableError'"
    v-bind="errorProps"
  />
</template>
