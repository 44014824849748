import type { Ref } from 'vue';
import { useHead } from '@unhead/vue';
import { computed } from 'vue';

import { getCordovaAppConfig } from '@leon-hub/cordova';
import { IconSize } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind } from '@components/buttons';

import type { VJumbotronProps } from 'web/src/components/Jumbotron/VJumbotron/types';
import { useIntercomEvents } from 'web/src/modules/intercom/composables';
import useSupport from 'web/src/modules/support/composables/useSupport';

import type { BaseErrorContentProps } from '../types';

export interface UseBaseErrorContentComposable {
  mainJumbotronProps: Ref<VJumbotronProps>;
  mainButtonProps: Ref<VButtonProps>;
  supportJumbotronProps: Ref<VJumbotronProps>;
  supportButtonProps: Ref<VButtonProps>;
  onMainClick(): void;
  onSupportClick(): void;
}

export default function useBaseErrorContent(props: BaseErrorContentProps): UseBaseErrorContentComposable {
  const { $translate } = useI18n();
  const { openIntercomMenu } = useIntercomEvents();
  const { isIntercomAvailable, isChatMode } = useSupport();

  const mainJumbotronProps = computed<VJumbotronProps>(() => ({
    icon: props.iconName,
    iconSize: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconSize.SIZE_96 : IconSize.SIZE_80,
    heading: props.title,
    text: props.text,
  }));

  const mainButtonProps = computed<VButtonProps>(() => ({
    kind: ButtonKind.PRIMARY,
    label: $translate('WEB2_ERROR_503_BTN_UPDATE_V2').value,
    fullWidth: true,
  }));

  const email = computed(() => {
    const companyEmail = process.env.VUE_APP_PLATFORM_CORDOVA ? getCordovaAppConfig().companyEmail : undefined;
    return companyEmail || $translate('WEB2_HELP_EMAIL').value;
  });

  const supportJumbotronProps = computed<VJumbotronProps>(() => ({
    text: $translate('WEB2_ERROR_TEXT_SUPPORT').value,
  }));

  const supportButtonProps = computed<VButtonProps>(() => ({
    kind: ButtonKind.BASE,
    label: props.isEmailAsLabel ? email.value : $translate('WEB2_ERROR_BTN_SUPPORT').value,
    isUppercase: false,
    rounded: true,
  }));

  function onMainClick(): void {
    window.location.reload();
  }

  function onSupportClick(): void {
    if (process.env.VUE_APP_FEATURE_INTERCOM_ENABLED && isIntercomAvailable.value && isChatMode.value) {
      openIntercomMenu();
    } else {
      window.location.href = `mailto:${email.value}`;
    }
  }

  useHead({
    meta: [{
      name: 'prerender-status-code',
      content: '503',
    }],
  });

  return {
    mainJumbotronProps,
    mainButtonProps,
    supportJumbotronProps,
    supportButtonProps,
    onMainClick,
    onSupportClick,
  };
}
