import type {
  Ref,
  StyleValue,
} from 'vue';
import {
  computed,
  nextTick,
  ref,
} from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';
import { useLifecycleResizeObserver } from '@leon-hub/vue-utils';

export interface UseDesktopModalHeightComposable {
  sidebar: Ref<HTMLElement | undefined>;
  contentStyles: Ref<StyleValue>;
}

export default function useDesktopModalHeight(): UseDesktopModalHeightComposable {
  const sidebar = ref<HTMLElement>();
  const sidebarHeight = ref<number>();

  const contentStyles = computed<StyleValue>(() => (
    sidebarHeight.value && !RouteName.CMS_PROMO_TERMS
      ? {
          maxHeight: `${sidebarHeight.value}px`,
        }
      : undefined
  ));

  if (!process.env.VUE_APP_FEATURE_DESKTOP_PROFILE_AS_PHONE_ENABLED) {
    useLifecycleResizeObserver(sidebar, async () => {
      await nextTick();
      sidebarHeight.value = sidebar.value?.scrollHeight;
    });
  }

  return {
    sidebar,
    contentStyles,
  };
}
