import { isOptionalString } from '@leon-hub/guards';

import {
  LEXIS_NEXIS_STORAGE_NAMESPACE_SESSION,
} from '../constants';
import { LexisNexisSessionStorage } from '../services/LexisNexisSessionStorage';

export function getLexisNexisSessionStorage (customerLogin: string): LexisNexisSessionStorage {
  return new LexisNexisSessionStorage({
    id: `lex-nex:${customerLogin}:${LEXIS_NEXIS_STORAGE_NAMESPACE_SESSION}`,
    guard: isOptionalString,
  })
}
