import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';

import { useIsDevIP } from 'web/src/modules/core/composables/root';

export default function createDevtoolsNavigationGuard(router: AppVueRouter): void {
  const { isDevIP } = useIsDevIP();

  router.beforeEach((to) => {
    if (to.name === RouteName.DEVTOOLS && !isDevIP.value) {
      return router.next({
        name: RouteName.ERROR_NOT_FOUND_404,
      });
    }
  });
}
