import { toRef, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useLocalStorageManager } from '@leon-hub/local-storage';
import { RouteName } from '@leon-hub/routing-config-names';
import { getLocationSearch } from '@leon-hub/service-locator-env';
import { Timer } from '@leon-hub/utils';

import { useRouterStore } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';
import { DeviceCustomerLoginStorage } from '@core/storage';

import { useRegistrationIdleRedirectStore } from 'web/src/modules/registration/store';

export interface UseGuestRedirect {
  redirect(): void;
}

const redirectCountKey = 'idleRedirectCount';

export default function useGuestRedirect(): UseGuestRedirect {
  const localStorage = useLocalStorageManager();
  const route = useRoute();
  const router = useRouter();
  const siteConfigStore = useSiteConfigStore();
  const idleRegistrationProps = toRef(siteConfigStore, 'idleRegistrationProps');
  const doRedirectGuestWithAccountToLogin = toRef(siteConfigStore, 'doRedirectGuestWithAccountToLogin');
  const redirectToLoginTimeout = toRef(siteConfigStore, 'redirectToLoginTimeout');
  const routerStore = useRouterStore();
  const currentRouteName = toRef(routerStore, 'currentRouteName');
  const { startTimeRegistration, isRedirectCalled } = toRefs(useRegistrationIdleRedirectStore());

  function redirect(): void {
    let lastLogin = DeviceCustomerLoginStorage.getLogin();

    if (
      doRedirectGuestWithAccountToLogin.value
      && redirectToLoginTimeout.value
      && lastLogin
      && route.redirectedFrom?.name !== RouteName.UNTRUSTED_DEVICE
      && route.redirectedFrom?.name !== RouteName.RESTORE_PASSWORD_ENTER_NEW_ONE
      && route.redirectedFrom?.name !== RouteName.REGISTRATION
      && route.redirectedFrom?.name !== RouteName.ANDROID
      && route.redirectedFrom?.name !== RouteName.REWARD_CENTER
    ) {
      Timer.setTimeout(() => {
        void router.push({ name: RouteName.LOGIN });
      }, redirectToLoginTimeout.value * 1000);
    } else {
      if (route.redirectedFrom?.name === RouteName.REWARD_CENTER) {
        void router.push({ name: RouteName.REWARD_CENTER_ABOUT });
        return;
      }

      const { idleRedirectCount, marketingLinkParam, marketingLinkIdleRedirect } = idleRegistrationProps.value;
      let { idleTimeToRedirect } = idleRegistrationProps.value;
      const urlParams = new URLSearchParams(getLocationSearch());

      if (marketingLinkParam && urlParams.get(marketingLinkParam)) {
        idleTimeToRedirect = marketingLinkIdleRedirect;
      }

      const idleRedirectCountLocal = localStorage.getItem(redirectCountKey) || 0;
      if (Number(idleRedirectCountLocal) < idleRedirectCount && idleTimeToRedirect) {
        Timer.setTimeout(() => {
          lastLogin = DeviceCustomerLoginStorage.getLogin();
          const doNotRedirectToRegistrationPages = [RouteName.LOGIN, RouteName.ANDROID];
          if (
            !lastLogin
            && !startTimeRegistration.value
            && !doNotRedirectToRegistrationPages.find((item) => item === currentRouteName.value)
          ) {
            isRedirectCalled.value = true;
            void router.push({ name: RouteName.REGISTRATION });
            localStorage.setItem(redirectCountKey, `${Number(idleRedirectCountLocal) + 1}`);
          }
        }, idleTimeToRedirect * 1000);
      }
    }
  }

  return {
    redirect,
  };
}
