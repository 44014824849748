import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';
import { useThemeStore } from '@core/theme';

export class ThemeModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    if (useThemeStore().isThemeFooterSwitcherEnabled) {
      router.addModalRoute({
        name: RouteName.THEME_SELECT_MODAL,
        path: '/theme',
        title: '{{$t(\'WEB2_THEME_SELECTION\')}}',
        component: () => import('web/src/modules/theme/views/ThemeRoutePage.vue'),
        prefetch: () => import('./prefetch/ThemePagePrefetch'),
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      });
    }
  }
}
