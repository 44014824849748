import { startLoginDeviceRoutineEventType, useAppEmitter } from '@core/app-emitter';
import { getDeferredDeviceIDStorage } from '@core/storage';

import { useLazyCustomerDataLogin } from 'web/src/modules/auth/utils/useLazyCustomerDataLogin';

export function onAppMounted(): void {
  void useLazyCustomerDataLogin().then(async (login) => {
    const storage = getDeferredDeviceIDStorage(login);
    const record = await storage.get();
    if ((record.exists && record.value)) {
      void storage.clear();
      useAppEmitter().emit(startLoginDeviceRoutineEventType);
    }
  });
}
