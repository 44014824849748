import type { AppVueRouter } from '@core/router';
import { useIsLoggedIn } from '@core/auth';
import { useCustomerDataStore } from '@core/customer';

import registrationCompletionGuard from 'web/src/modules/core/services/router/navigation-guards/registration-completion/registrationCompletionGuard';

export default function createRegistrationNavigationGuard(router: AppVueRouter): void {
  const { isLoggedIn } = useIsLoggedIn();
  const customerDataStore = useCustomerDataStore();

  router.beforeEach((to) => {
    const guard = registrationCompletionGuard({
      isLoggedIn: isLoggedIn.value,
      isSimpleRegistrant: customerDataStore.isSimpleRegistrant,
      isExAllowedCountry: customerDataStore.isExAllowedCountry,
      isRegistrationFinish: customerDataStore.isRegistrationFinish,
      isCountryConfirmed: customerDataStore.isCountryConfirmed,
      isForbiddenCountry: customerDataStore.isForbiddenCountry,
      isAsoTermsConfirmationNeeded: customerDataStore.isAsoTermsConfirmationNeeded,
      to,
    });

    if (guard) {
      return router.next(guard);
    }
  });
}
