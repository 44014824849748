import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class RulesModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      router.addDefaultRoute({
        name: RouteName.RULES,
        path: '/rules/:levelOneId?/:levelTwoId?/:levelThreeId?',
        prefetch: () => import('web/src/modules/rules/prefetch/RulesPrefetch'),
        component: () => import('web/src/modules/rules/components/RulesRouteComponent.vue'),
        leftSideBar: () => import('web/src/modules/rules/components/RulesSidebarRouteComponent.vue'),
        navigation: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? () => import('web/src/modules/rules/components/RulesNavigationBar.vue') : undefined,
      });

      if (!process.env.VUE_APP_PRODUCT_LEONRU) {
        router.addDefaultRoute({
          name: RouteName.BETTING_TERMS_WEB2,
          path: '/terms',
          props: {
            cmsKey: 'BETTING_TERMS_WEB2',
            title: '{{$t(\'WEB2_PROMO_TERMS_TITLE\')}}',
            isDownloadButtonEnabled: true,
            termsTitle: 'WEB2_PROMO_TERMS_TITLE',
          },
          leftSideBar: () => import('web/src/modules/sidebar/views/SidebarMenuKnowHowRouteComponent/SidebarMenuKnowHowRouteComponent.vue'),
          component: () => import('web/src/modules/rules/components/RulesContentDefault.vue'),
        });
      }
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.addModalRoute({
        name: RouteName.RULES,
        path: '/rules/:levelOneId?/:levelTwoId?/:levelThreeId?',
        prefetch: () => import('web/src/modules/rules/prefetch/RulesPrefetch'),
        navigation: () => import('web/src/modules/rules/components/RulesTabsRouteComponent.vue'),
        component: () => import('web/src/modules/rules/components/RulesRouteComponent.vue'),
        topBar: () => import('web/src/modules/rules/components/RulesTopBarRouteComponent.vue'),
        topBarProps: {
          isPrintButtonEnabled: false,
        },
      });

      if (!process.env.VUE_APP_PRODUCT_LEONRU) {
        router.addModalRoute({
          name: RouteName.BETTING_TERMS_WEB2,
          path: '/terms',
          props: {
            cmsKey: 'BETTING_TERMS_MOBILE_WEB2',
            isDownloadButtonEnabled: true,
          },
          title: '{{$t(\'WEB2_PROMO_TERMS_TITLE\')}}',
          component: () => import('web/src/modules/rules/components/RulesContentDefault.vue'),
          topBar: () => import('web/src/modules/rules/components/RulesTopBarRouteComponent.vue'),
          topBarProps: {
            cmsKey: 'BETTING_TERMS_MOBILE_WEB2',
            termsTitle: 'WEB2_PROMO_TERMS_TITLE',
            isPrintButtonEnabled: true,
          },
        });
      }
    }
  }
}
