import type { Ref } from 'vue';
import { computed } from 'vue';

import {
  IconName,
} from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { BaseErrorContentProps } from '../../BaseErrorContent/types';

export interface UseServiceUnavailableErrorComposable {
  errorProps: Ref<BaseErrorContentProps>;
}

export default function useServiceUnavailableError(): UseServiceUnavailableErrorComposable {
  const { $translate } = useI18n();

  const errorProps = computed<BaseErrorContentProps>(() => ({
    title: $translate('WEB2_ERROR_503_TITLE_V2').value,
    text: `
      ${$translate('WEB2_ERROR_503_TEXT1_V2').value}
      ${$translate('WEB2_ERROR_503_TEXT2_V2').value}
      ${$translate('WEB2_ERROR_503_TEXT3_V2').value}
    `.trim(),
    iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_GEARS : IconName.GEARS,
  }));

  return {
    errorProps,
  };
}
