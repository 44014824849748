import { assert } from '@leon-hub/guards';
import { CustomerRouteAccessRole, isCustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { useIsLoggedIn } from '@core/auth';

import { isRouteAllowed } from '../utils';

function isOptionalAccess(value: unknown): value is CustomerRouteAccessRole | undefined {
  return !value || isCustomerRouteAccessRole(value);
}

export default function createAccessRestrictionsNavigationGuard(router: AppVueRouter): void {
  router.beforeEach((to, from) => {
    if (!to.name) {
      return router.next({
        name: RouteName.ERROR_NOT_FOUND_404,
      });
    }

    const { access } = to.meta;
    const { access: fromAccess } = from.meta;
    assert(isOptionalAccess(access));
    assert(isOptionalAccess(fromAccess));

    if (access === CustomerRouteAccessRole.FORBIDDEN) {
      return router.next({
        name: RouteName.ERROR_FORBIDDEN_403,
      });
    }

    const { isLoggedIn } = useIsLoggedIn();

    if (!router.isModalReplaced() && !isRouteAllowed(access, isLoggedIn.value)) {
      if (!isLoggedIn.value) {
        return router.next({
          name: RouteName.LOGIN,
          state: {
            toRoute: to.fullPath,
          },
        });
      } else {
        return router.next({
          name: RouteName.HOME,
        });
      }
    }
  });
}
