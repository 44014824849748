import { EditionValueLayoutPhone } from '@leon-hub/environment-editions';

import { useRootStore } from '@core/root';
import { useSiteConfigStore } from '@core/site-config';
import { useCookies } from '@core/storage';

export default async function forcePhoneLayout(): Promise<void> {
  if (
    process.env.VUE_APP_PLATFORM_WEB
    && process.env.VUE_APP_LAYOUT_DESKTOP
    && !process.env.VUE_APP_PRERENDER
    && useSiteConfigStore().isForcedLayoutEnabled
    && window.screen.width < useRootStore().desktopScreenMinWidth
  ) {
    await useCookies().set({ name: 'forcedLayout', value: EditionValueLayoutPhone });
    window.location.reload();
  }
}
