import type { RouteLocationNormalized } from 'vue-router';

import {
  assert,
  isObject,
  isOptionalString,
} from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, ModalDesktopPreset } from '@core/router';

import type { DesktopModal } from 'web/src/modules/core/types';
import {
  useDesktopModalStore,
} from 'web/src/modules/core/store';

import ModalOpenedRouteError from '../errors/ModalOpenedRouteError';
import processAfterRoute from './history-state/processAfterRoute';
import prefetchRouteComponents from './prefetchRouteComponents';

let modalToOpen: RouteLocationNormalized | undefined;

function createBeforeEach(router: AppVueRouter): void {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  router.beforeEach(async (to, from) => {
    const { modalPreset } = to.meta;
    assert(isOptionalString(modalPreset));

    const desktopModalStore = useDesktopModalStore();
    const { setDesktopModal } = desktopModalStore;

    if (desktopModalStore.desktopModal && !modalPreset) {
      setDesktopModal(undefined);
      if (!router.isPopStateDetected()) {
        await router.closeModal();
        if (from.fullPath !== to.fullPath) {
          return to;
        }
        return false;
      }
    }

    if (modalPreset) {
      if (!from.name) {
        // direct link to modal
        modalToOpen = to;
        return router.next({
          name: RouteName.HOME,
          replace: true,
        });
      }

      const resolvedPromise = await prefetchRouteComponents(
        to,
        from,
        router,
      );

      if (isObject(resolvedPromise)) {
        if (resolvedPromise && resolvedPromise.name !== to.name) {
          return router.next(resolvedPromise);
        }
      }

      processAfterRoute(to, from, router);

      setDesktopModal({
        ...(to.meta.desktopModal as Pick<DesktopModal, 'default' | 'topBar' | 'isProfile' | 'isNoMinHeightLimit'>),
        preset: modalPreset as ModalDesktopPreset,
        route: to,
      });

      return new ModalOpenedRouteError();
    }
  });
}

function createAfterEach(router: AppVueRouter): void {
  router.afterEach(() => {
    if (modalToOpen) {
      void router.push({
        name: String(modalToOpen.name),
        params: modalToOpen.params,
        query: modalToOpen.query,
      });
      modalToOpen = undefined;
    }
  });
}

export default function createDesktopModalNavigationGuard(
  router: AppVueRouter,
): void {
  if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
    throw new Error('Only for DESKTOP layout');
  }

  createBeforeEach(router);
  createAfterEach(router);
}
