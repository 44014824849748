import type {
  RouteLocationNormalized,
} from 'vue-router';

import {
  assert,
  isFunction,
} from '@leon-hub/guards';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';

import { importComponent } from 'web/src/modules/core/utils';

function isPrefetchFunction(value: unknown): value is () => Promise<{
  default: new() => unknown;
}> {
  return isFunction(value);
}

export default async function prefetchRouteComponents(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  router: AppVueRouter,
): AsyncAbstractPrefetchResult {
  const prefetch = to.meta?.prefetch;
  if (!prefetch) {
    return;
  }

  assert(isPrefetchFunction(prefetch));

  const AbstractPrefetchClass = isPrefetchFunction(prefetch)
    ? await importComponent(prefetch)
    : prefetch;

  const newClass = new AbstractPrefetchClass();
  if (!(newClass instanceof AbstractPrefetch)) {
    throw new TypeError('Imported prefetch is not of AbstractPrefetch class');
  }

  return newClass.prefetch(router, to, from);
}
