import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

export class VerifyEmailModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.VERIFY_EMAIL_STATUS_PAGE,
      path: '/verify-email',
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      component: () => import('./views/VerifyEmailRouteComponent'),
    });

    router.addModalRoute({
      name: RouteName.VERIFY_EMAIL,
      path: '/verify-email/:token',
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      component: () => import('./views/VerifyEmailRouteComponent'),
    });
  }
}
