import { toRef } from 'vue';

import { useIsLoggedIn } from '@core/auth';
import { useCustomerDataStore } from '@core/customer';

import { useRef } from 'web/src/utils/vue/useRef';

export async function useLazyCustomerDataLogin(): Promise<string> {
  await Promise.all([
    // Wait for authorization customer data.
    useRef(toRef(useCustomerDataStore(), 'login')),
    useRef(useIsLoggedIn().isLoggedIn),
  ]);
  return useCustomerDataStore().login;
}
