import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';

export interface HeaderRegistrationComposable {
  onLoginClick(): void;
  onRegisterClick(): void;
}

export default function useHeaderRegistration(): HeaderRegistrationComposable {
  const analytics = useAnalytics();

  function onLoginClick(): void {
    analytics.push(AnalyticsEvent.CLICK_MAP, { clickCounter: { enter: 'headerEnter' } });
  }

  function onRegisterClick(): void {
    analytics.push(AnalyticsEvent.CLICK_MAP, { clickCounter: { registration: 'headerRegistration' } });
  }

  return {
    onLoginClick,
    onRegisterClick,
  };
}
