import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

export class RestorePasswordModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.RESTORE_PASSWORD,
      path: '/login/restore',
      access: CustomerRouteAccessRole.ANONYMOUS,
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      prefetch: () => import('./prefetch/RestoreMainPagePrefetch'),
      component: () => import('./components/RestorePasswordMainRoutePage/RestorePasswordMainRoutePage.vue'),
      topBar: () => import('./components/RestorePasswordTopBarRouteComponent/RestorePasswordTopBarRouteComponent.vue'),
    });

    router.addModalRoute({
      name: RouteName.RESTORE_PASSWORD_ENTER_NEW_ONE,
      path: '/pwdreset/:uid?/:email?',
      access: CustomerRouteAccessRole.ANONYMOUS,
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      prefetch: () => import('./prefetch/RestorePasswordLastStepPagePrefetch'),
      component: () => import('./components/RestorePasswordLastRoutePage/RestorePasswordLastRoutePage.vue'),
      topBar: () => import('./components/RestorePasswordTopBarRouteComponent/RestorePasswordTopBarRouteComponent.vue'),
    });

    router.addModalRoute({
      name: RouteName.UNTRUSTED_DEVICE,
      path: '/untrusted-device/:hashId',
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      component: () => import('./submodules/UntrustedDeviceRouteComponent/UntrustedDeviceRouteComponent.vue'),
      title: '{{$t(\'WEB2_PASSWORD_CHANGE\')}}',
    });

    router.addPrefetchRoute({
      name: RouteName.RESTORE_PASSWORD_APP,
      path: '/pwdresetapp/:applink',
      access: CustomerRouteAccessRole.ANONYMOUS,
      prefetch: () => import('./prefetch/RestorePasswordAppPrefetch'),
    });
  }
}
