import type {
  RouteLocationNormalized,
} from 'vue-router';

import {
  assert,
  isFunction,
} from '@leon-hub/guards';

import type { AppVueRouter } from '@core/router';

import { AbstractPageSeoController } from 'web/src/modules/core/services/router/seo';
import { importComponent } from 'web/src/modules/core/utils';

function isPrefetchFunction(value: unknown): value is () => Promise<{
  default: new() => unknown;
}> {
  return isFunction(value);
}

const defaultSeoController = () => import('web/src/modules/seo/DefaultPageSeoController');

async function loadSeoRouteComponents(to: RouteLocationNormalized): Promise<AbstractPageSeoController> {
  const importSeoController = to.meta?.seoController || defaultSeoController;

  assert(isPrefetchFunction(importSeoController));

  const SeoControllerClass = await importComponent(importSeoController);
  const seoController = new SeoControllerClass();

  if (!(seoController instanceof AbstractPageSeoController)) {
    throw new TypeError('Imported seo controller is not of AbstractPageSeoController class');
  }

  return seoController;
}

export function createSeoMetaDataNavigationGuard(router: AppVueRouter): void {
  router.beforeEach((
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => {
    void loadSeoRouteComponents(to).then(async (seoController) => {
      const config = await seoController.getSeoConfig(router, from, to);
      seoController.loadSeoMetaDataFor(config);
    });
  });
}
