import type { Plugin } from 'vue';

interface CmsUtils {
  selectOne(value: string): string;
  phoneCall(phoneNumber: string): void;
  toggleBlocksVisibility(selector: string): void;
  toggleSelectorClass(selector: string, toggleClass: string): void;
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $cmsUtils: CmsUtils;
  }
}

/** Get random part from string format a|b|c */
function randomSelectOnePart(value: string): string {
  const splitValue = value.split('|');
  const randomValue = Math.floor(Math.random() * splitValue.length);
  return splitValue[randomValue] || '';
}

const CmsUtilsPlugin: Plugin = {
  install(app): void {
    app.config.globalProperties.$cmsUtils = <CmsUtils>{
      selectOne: randomSelectOnePart,
      phoneCall(phoneNumber: string): void {
        try {
          if (process.env.VUE_APP_RENDERING_CSR) {
            window.location.href = `tel:${phoneNumber}`;
          }
        } catch {
          //
        }
      },
      toggleBlocksVisibility(selector: string): void {
        try {
          if (process.env.VUE_APP_RENDERING_CSR) {
            const elements = document.querySelectorAll(selector);
            const hiddenClassName = 'hidden';
            for (const element of elements) {
              if (element.classList.contains(hiddenClassName)) {
                element.classList.remove(hiddenClassName);
              } else {
                element.classList.add(hiddenClassName);
              }
            }
          }
        } catch {
          //
        }
      },
      toggleSelectorClass(selector: string, toggleClass: string): void {
        try {
          if (process.env.VUE_APP_RENDERING_CSR) {
            const elements = document.querySelectorAll(selector);
            const toggleClassName = toggleClass;
            for (const element of elements) {
              if (element.classList.contains(toggleClassName)) {
                element.classList.remove(toggleClassName);
              } else {
                element.classList.add(toggleClassName);
              }
            }
          }
        } catch {
          //
        }
      },
    };
  },
};

export default CmsUtilsPlugin;
