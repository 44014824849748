import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class DevToolsModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addDefaultRoute({
      name: RouteName.DEVTOOLS,
      path: '/dev-tools',
      component: () => import('web/src/modules/dev-tools/pages/DevtoolsRoutePage/DevtoolsRoutePage.vue'),
    });
  }
}
