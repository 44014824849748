import type { AppModule } from '@core/modularization';

import { LocalizationModule } from '@modules/localization-module';

import { AccessOptionsModule } from 'web/src/modules/access-options/AccessOptionsModule';
import { AffiliatesModule } from 'web/src/modules/affiliates/AffiliatesModule';
import { BenefitsProgramModule } from 'web/src/modules/benefits-program/BenefitsProgramModule';
import { BonusesModule } from 'web/src/modules/bonuses/BonusesModule';
import { CasinoLoyaltyProgramModule } from 'web/src/modules/casino-loyalty-program/CasinoLoyaltyProgramModule';
import { CasinoBetgamesModule } from 'web/src/modules/casino/submodules/betgames/CasinoBetgamesModule';
import { CasinoGameModule } from 'web/src/modules/casino/submodules/game/CasinoGameModule';
import { CasinoGroupsModule } from 'web/src/modules/casino/submodules/groups/CasinoGroupsModule';
import { CasinoLobbyModule } from 'web/src/modules/casino/submodules/lobby/CasinoLobbyModule';
import { CmsModule } from 'web/src/modules/cms/CmsModule';
import { CordovaModule } from 'web/src/modules/cordova/CordovaModule';
import { CustomerNotificationsModule } from 'web/src/modules/customer-notifications/CustomerNotificationsModule';
import { DevToolsModule } from 'web/src/modules/dev-tools/DevToolsModule';
import { ErrorsModule } from 'web/src/modules/errors/ErrorsModule';
import { FortuneWheelModule } from 'web/src/modules/fortune-wheel/FortuneWheelModule';
import { HomeModule } from 'web/src/modules/home/HomeModule';
import { IdentificationModule } from 'web/src/modules/identification/IdentificationModule';
import { LandingsModule } from 'web/src/modules/landings/LandingsModule';
import { LoginModule } from 'web/src/modules/login/LoginModule';
import { OnboardingModule } from 'web/src/modules/onboarding/OnboardingModule';
import { PartnersModule } from 'web/src/modules/partners/PartnersModule';
import { PaymentsModule } from 'web/src/modules/payments/PaymentsModule';
import { PinCodeModule } from 'web/src/modules/pin-code/PinCodeModule';
import { ProfileModule } from 'web/src/modules/profile/ProfileModule';
import { ProfileBonusModule } from 'web/src/modules/profile/submodules/bonuses/ProfileBonusModule';
import { CustomTransactionHistoryModule } from 'web/src/modules/profile/submodules/customer-history/CustomTransactionHistoryModule';
import { ResponsibleGamblingModule } from 'web/src/modules/profile/submodules/responsible-gambling/ResponsibleGamblingModule';
import { PromotionsModule } from 'web/src/modules/promotions/PromotionsModule';
import { PushNotificationsModule } from 'web/src/modules/push-notifications/PushNotificationsModule';
import { QuickBetsModule } from 'web/src/modules/quick-bets/QuickBetsModule';
import { ReferralProgramModule } from 'web/src/modules/referral-program/ReferralProgramModule';
import { RegistrationCompletionModule } from 'web/src/modules/registration-completion/RegistrationCompletionModule';
import { SimplifiedRegistrationModule } from 'web/src/modules/registration/submodules/simplified-registration';
import { StepRegistrationModule } from 'web/src/modules/registration/submodules/step-registration/StepRegistrationModule';
import { TsupisRegistrationModule } from 'web/src/modules/registration/submodules/tsupis-registration';
import { ResponsibleGamblingModuleOld } from 'web/src/modules/responsible-gambling/ResponsibleGamblingModuleOld';
import { RestorePasswordModule } from 'web/src/modules/restore-password/RestorePasswordModule';
import { RulesModule } from 'web/src/modules/rules/RulesModule';
import { SearchModule } from 'web/src/modules/search/SearchModule';
import { SlipModule } from 'web/src/modules/slip/SlipModule';
import {
  CybersportModule,
  SportlineModule,
  SportlineSportsModule,
} from 'web/src/modules/sportline/SportlineModule';
import { SupportModule } from 'web/src/modules/support/SupportModule';
import { ThemeModule } from 'web/src/modules/theme/ThemeModule';
import { TFAModule } from 'web/src/modules/two-factor-authentication/TFAModule';
import { VerifyEmailModule } from 'web/src/modules/verify-email/VerifyEmailModule';

export default function getAppModules(): AppModule[] {
  const modules: AppModule[] = [
    new LoginModule(),
    new SupportModule(),
    new HomeModule(),
    new ProfileModule(),
    new ReferralProgramModule(),
    new OnboardingModule(),
    new FortuneWheelModule(),
    new ProfileBonusModule(),
    new SearchModule(),
    new CustomerNotificationsModule(),
    new PushNotificationsModule(),
    new ErrorsModule(),
    new AccessOptionsModule(),
    new LandingsModule(),
    new RulesModule(),
    new PaymentsModule(),
    new PinCodeModule(),
    new AffiliatesModule(),
    new ThemeModule(),
    new VerifyEmailModule(),
    new CustomTransactionHistoryModule(),
    new TFAModule(),
    new RestorePasswordModule(),
    new ResponsibleGamblingModuleOld(),
    new PromotionsModule(),
    new BonusesModule(),
    new CmsModule(),
  ];

  if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
    modules.push(new LocalizationModule());
  }

  if (process.env.VUE_APP_FEATURE_SPONSORED_CLUBS_ENABLED) {
    modules.push(new PartnersModule());
  }

  if (!process.env.VUE_APP_PRERENDER) {
    modules.push(new DevToolsModule());
  }

  if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED) {
    modules.push(
      new SportlineModule(),
      new SportlineSportsModule(),
      new CybersportModule(),
      new QuickBetsModule(),
      new SlipModule(),
    );
  }

  if (process.env.VUE_APP_FEATURE_IDENT_ENABLED) {
    modules.push(new IdentificationModule());
  }

  if (process.env.VUE_APP_FEATURE_TSUPIS_ENABLED) {
    modules.push(new TsupisRegistrationModule());
  }

  if (process.env.VUE_APP_FEATURE_CASINO_LOYALTY_PROGRAM_ENABLED) {
    modules.push(new CasinoLoyaltyProgramModule());
  }

  if (process.env.VUE_APP_FEATURE_RESPONSIBLE_GAMBLING_V2_ENABLED) {
    modules.push(new ResponsibleGamblingModule());
  }

  if (process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED) {
    modules.push(
      new StepRegistrationModule(),
      new RegistrationCompletionModule(),
    );
  }
  if (process.env.VUE_APP_FEATURE_SIMPLIFIED_REGISTRATION_ENABLED) {
    modules.push(
      new SimplifiedRegistrationModule(),
      new RegistrationCompletionModule(),
    );
  }

  if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    modules.push(
      new CasinoBetgamesModule(),
      new CasinoGameModule(),
      new CasinoGroupsModule(),
      new CasinoLobbyModule(),
    );
  }

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    modules.push(new CordovaModule());
  }

  if (process.env.VUE_APP_FEATURE_BENEFITS_PROGRAM_ENABLED) {
    modules.push(new BenefitsProgramModule());
  }

  return modules;
}
