import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { useRouterStore } from '@core/router';

export default function performLogout(router: AppVueRouter): void {
  const { currentRoute, getAnonymousRouteHistoryDelta } = useRouterStore();

  if (currentRoute?.meta.access === CustomerRouteAccessRole.AUTHORIZED) {
    const delta = getAnonymousRouteHistoryDelta();
    if (delta !== 0) {
      router.go(delta);
    } else {
      void router.replace({
        name: RouteName.HOME,
      });
    }
  }
}
