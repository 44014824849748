import { getLocationPath, getLocationSearch } from '@leon-hub/service-locator-env';

import {
  getNewUri,
  prepareRedirectUri,
} from '@core/router';

export default function checkTrailingSlash(currentRedirectUri: string, currentUrlPrefix: string): string {
  let newRedirectUri = currentRedirectUri;

  const pathname = getLocationPath();
  const search = getLocationSearch();

  const uri = `${pathname}${search}`;
  const newUri = getNewUri(currentUrlPrefix, currentUrlPrefix);

  if (newUri !== uri) {
    newRedirectUri = prepareRedirectUri(newUri);

    if (process.env.VUE_APP_RENDERING_CSR && !process.env.VUE_APP_PRERENDER) {
      window.history.replaceState(
        null,
        '',
        newUri,
      );
    }
  }

  return newRedirectUri;
}
