import type { RouteLocationNormalized } from 'vue-router';

import { getQueryManager } from '@leon-hub/query-manager';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { doRootSettingsRequest } from '@core/root';
import { useRouterStore } from '@core/router';

import type { RouterHistoryState } from 'web/src/modules/core/types';

import processDesktopHistoryState from './processDesktopHistoryState';
import processPhoneHistoryState from './processPhoneHistoryState';

let lastHistoryState: RouterHistoryState | undefined;

export default function processAfterRoute(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  router: AppVueRouter,
): void {
  if (from.name && !router.isPopStateDetected() && from.redirectedFrom?.fullPath !== to.fullPath) {
    const headers = getQueryManager(to.fullPath).getRootHeaderParameters();

    if (Object.keys(headers).length > 0) {
      void doRootSettingsRequest(headers);
    }
  }
  if (process.env.VUE_APP_RENDERING_SSR) {
    return;
  }

  if (!from.name) {
    window.history.replaceState({
      ...window.history.state,
      meta: undefined,
    }, '');
  }

  if (process.env.VUE_APP_LAYOUT_PHONE) {
    processPhoneHistoryState(to, from, router, lastHistoryState);
  }

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    processDesktopHistoryState(to, router, lastHistoryState);
  }

  if (router.doSaveScrollPosition()) {
    to.meta.doSaveScrollPosition = true;
  }

  window.history.replaceState({
    ...window.history.state,
    historyLength: window.history.length,
  }, '');

  lastHistoryState = window.history.state;

  router.processAfterEach();

  const {
    setBackButtonInTopBar,
    setCurrentRoute,
    setCurrentRouteIndex,
    setLastNoModalRouteIndex,
    setPreviousRouteName,
    setPreviousRouteUrl,
  } = useRouterStore();
  setBackButtonInTopBar(!!window.history.state?.meta?.isBackButton);
  setCurrentRoute(to, router.resolve(to).href);
  setCurrentRouteIndex(window.history.state?.realPosition || window.history.state?.position || 0);

  if (!window.history.state?.meta?.isModalRoute) {
    setLastNoModalRouteIndex(window.history.state?.position || 0);
  }

  if (window.history.state?.back) {
    setPreviousRouteUrl(window.history.state.back);
    setPreviousRouteName(router.resolve(window.history.state.back).name ?? RouteName.HOME);
  } else {
    setPreviousRouteUrl(undefined);
    setPreviousRouteName(undefined);
  }
}
