import type { Plugin } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

import type { SharedRouteNameType } from '@leon-hub/routing-config-names';
import { RouteName } from '@leon-hub/routing-config-names';

type RouteTo = (name: SharedRouteNameType, options?: RouteLocationNamedRaw) => RouteLocationNamedRaw;

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $to: RouteTo;
    /**
     * @deprecated use RouteName instead
     */
    $routeName: typeof RouteName;
  }
}

const RouterNamePlugin: Plugin = {
  install(app): void {
    app.config.globalProperties.$routeName = RouteName;

    app.config.globalProperties.$to = (name: SharedRouteNameType, options?: RouteLocationNamedRaw): RouteLocationNamedRaw => ({
      ...(options ?? {}),
      name,
    });
  },
};

export default RouterNamePlugin;
