import type {
  Component,
  Ref,
} from 'vue';
import {
  ref,
  shallowRef,
  watch,
} from 'vue';

import { useDialogsStore } from '@core/dialogs';

import { importComponent } from 'web/src/modules/core/utils';
import { AccessDeniedModalId } from 'web/src/modules/dialogs/consts';

import useDesktopModal from './useDesktopModal';

export interface UseDesktopModalComponentsComposable {
  DefaultComponent: Ref<Component | null>;
  Navigation: Ref<Component | null>;
  defaultProps: Ref<Record<string, unknown>>;
  TopBar: Ref<Component | null>;
  topBarProps: Ref<Record<string, unknown>>;
}

export default function useDesktopModalComponents(): UseDesktopModalComponentsComposable {
  const dialogsStore = useDialogsStore();
  const { desktopModal } = useDesktopModal();

  const DefaultComponent = shallowRef<Component | null>(null);
  const defaultProps = ref<Record<string, unknown>>({});
  const TopBar = shallowRef<Component | null>(null);
  const topBarProps = ref<Record<string, unknown>>({});
  const Navigation = shallowRef<Component | null>(null);

  watch(desktopModal, (newValue) => {
    // TODO: move to module after modals to pinia
    if (!newValue && dialogsStore.currentDialog && dialogsStore.currentDialog.dialog.id !== AccessDeniedModalId) {
      dialogsStore.closeDialog(dialogsStore.currentDialog.dialog.id);
    }

    if (!newValue) {
      DefaultComponent.value = null;
      TopBar.value = null;
      Navigation.value = null;
      topBarProps.value = {};
      defaultProps.value = {};
    } else {
      void importComponent(
        newValue.default.component,
      ).then((result: Component) => {
        DefaultComponent.value = result;
        defaultProps.value = newValue.default.props === true
          ? newValue.route.params
          : newValue.default.props ?? {};
      });

      void importComponent(
        newValue.topBar.component,
      ).then((result: Component) => {
        TopBar.value = result;
        topBarProps.value = newValue.topBar.props === true
          ? newValue.route.params
          : newValue.topBar.props ?? {};
      });

      if (newValue.navigation) {
        void importComponent(
          newValue.navigation.component,
        ).then((result: Component) => {
          Navigation.value = result;
        });
      } else {
        Navigation.value = null;
      }
    }
  });

  return {
    DefaultComponent,
    defaultProps,
    TopBar,
    topBarProps,
    Navigation,
  };
}
