import { useApiSettingsStore, useSyncDateTimeStore } from '@core/app-settings';
import { useI18nStore } from '@core/i18n';
import { useNavigationStore } from '@core/navigation';
import { useRootStore } from '@core/root';
import { useRouterStore } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

import type { PrefetchConsumer } from './types/PrefetchConsumer';

export const prefetchConsumer: PrefetchConsumer = {
  root: (state): void => useRootStore().handleData(state),
  api: (state): void => useApiSettingsStore().handleApiSettings(state),
  siteConfig: (state): void => useSiteConfigStore().handleSiteConfigSettings(state),
  routing: (state): void => useRouterStore().handleAppRouteConfigs(state),
  navigation: (state): void => useNavigationStore().handleDefaultNavigationConfig(state),
  i18n: (state): void => useI18nStore().updateKeKeys(state),
  serverTime: (time): void => useSyncDateTimeStore().setServerTime(time),
};

export type PrefetchConsumerKey = keyof PrefetchConsumer;
