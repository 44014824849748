import { watch } from 'vue';

import type { AppVueRouter } from '@core/router';
import { useIsLoggedIn } from '@core/auth';

import performLogin from './performLogin';
import performLogout from './performLogout';

export default function syncAuthStateToRoute(router: AppVueRouter): void {
  const { isLoggedIn } = useIsLoggedIn();

  watch(isLoggedIn, (newValue) => {
    if (newValue) {
      performLogin(router);
    } else {
      performLogout(router);
    }
  });
}
