import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

export class LocalizationModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      router.addModalRoute({
        name: RouteName.LANGUAGE,
        path: '/language',
        title: '{{$t(\'WEB2_LANGUAGE_SELECTION\')}}',
        component: () => import('./pages/LanguageSelector/LanguageSelector.vue'),
        prefetch: () => import('./prefetch/LanguagePagePrefetch'),
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      });
    }
  }
}
