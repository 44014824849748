import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

export class LoginModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.LOGIN,
      path: '/login',
      access: CustomerRouteAccessRole.ANONYMOUS,
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      title: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? undefined
        : '{{$t(\'THE_SITE_ENTRANCE\')}}',
      component: () => import('web/src/modules/login/pages/LoginRoutePage/LoginRoutePage.vue'),
      prefetch: () => import('./prefetch/LoginModulePrefetch'),
    });

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      router.addModalRoute({
        name: RouteName.FLOW_LOGIN,
        path: '/flow-login',
        access: CustomerRouteAccessRole.ANONYMOUS,

        topBar: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? undefined
          : () => import('web/src/modules/core/views/SimpleBrandTopBarRouteComponent/SimpleBrandTopBarRouteComponent.vue'),
        component: () => import('web/src/modules/login/pages/LoginRoutePage/LoginRoutePage.vue'),
        prefetch: () => import('./prefetch/LoginModulePrefetch'),
        props: {
          isFlowLogin: true,
        },
      });
    }
  }
}
