<script lang="ts" setup>
import BaseErrorContent from '../BaseErrorContent/BaseErrorContent.vue';
import { useConnectionError } from './composables';

const {
  errorProps,
} = useConnectionError();
</script>

<template>
  <BaseErrorContent v-auto-id="'ConnectionError'"
    v-bind="errorProps"
  />
</template>
