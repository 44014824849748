import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

export class RegistrationCompletionModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.REGISTRATION_COMPLETION,
      path: '/registration/completion/:customerAction/:paymentSystemId?',
      access: CustomerRouteAccessRole.AUTHORIZED,
      prefetch: () => import('./prefetch/RegistrationCompletionPrefetch'),
      component: () => import('web/src/modules/registration-completion/pages/RegistrationCompletionRoutePage/RegistrationCompletionRoutePage.vue'),
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      topBar: () => import('web/src/modules/registration-completion/views/RegistrationCompletionTopBarRouteComponent/RegistrationCompletionTopBarRouteComponent.vue'),
    });
  }
}
