import type { AppVueRouter } from '@core/router';
import { useRouterStore } from '@core/router';

import processAfterRoute from './history-state/processAfterRoute';
import prefetchRouteComponents from './prefetchRouteComponents';

function getContentElement(): HTMLElement | null {
  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    return document.getElementById('js__content-scroll');
  }

  return window.document.documentElement;
}

export default function createBaseNavigationGuard(router: AppVueRouter): void {
  router.beforeEach(async (to, from) => {
    useRouterStore().setNextRouteName(to.name);

    if (process.env.VUE_APP_RENDERING_CSR && !router.isPopStateDetected()) {
      window.history.replaceState({
        ...window.history.state,
        scrollTop: from.meta.resetScrollPosition
          ? 0
          : (getContentElement()?.scrollTop ?? 0),
      }, '');
    }

    const result = await prefetchRouteComponents(
      to,
      from,
      router,
    );

    if (typeof result === 'boolean') {
      return result;
    }

    return router.next(result);
  });

  router.afterEach((to, from) => {
    const { isPopStateDetected } = router;

    if (process.env.VUE_APP_LAYOUT_PHONE
      || to.fullPath !== from.fullPath
      || !useRouterStore().currentRoute?.meta?.modalPreset
      || isPopStateDetected()
    ) {
      processAfterRoute(to, from, router);
    }

    useRouterStore().setRealCurrentRouteName(to.name);
    if (!from.name || to.fullPath !== from.fullPath) {
      useRouterStore().setNextRouteName(undefined);
    }

    if (process.env.VUE_APP_LAYOUT_DESKTOP
      && to.fullPath === from.fullPath
      && window.history.state?.current !== to.fullPath
      && !isPopStateDetected) {
      void router.closeModal();
    }

    if (to.name !== from.name && to.meta.transitionTo !== from.meta.transitionTo) {
      if (to.meta.transitionTo) {
        to.meta.transition = to.meta.transitionTo;
      }

      if (from.meta.transitionFrom) {
        to.meta.transition = from.meta.transitionFrom;
      }
    }
  });
}
