import { CustomerRouteAccessRole } from '@leon-hub/routing-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';
import { ModalDesktopPreset } from '@core/router';

export class PushNotificationsModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.PUSH_NOTIFICATIONS_BETS_ONBOARDING,
      path: '/push-notifications-bets-onboarding',
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      access: CustomerRouteAccessRole.AUTHORIZED,

      component: () => import('web/src/modules/push-notifications/pages/PushNotificationsBetsOnboardingRoutePage/PushNotificationsBetsOnboardingRoutePage.vue'),
    });
  }
}
