import type { Router } from 'vue-router';
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type {
  AppVueRouter,
} from '@core/router';
import { useI18nStore } from '@core/i18n';
import { useRouterStore } from '@core/router';

import { getBaseUrl } from 'web/src/modules/core/utils';

import ModalOpenedRouteError from './errors/ModalOpenedRouteError';
import getAppVueRouter from './getAppVueRouter';
import {
  checkLanguagePrefix,
  create301RedirectNavigationGuard,
  createAccessRestrictionsNavigationGuard,
  createBaseNavigationGuard,
  createCordovaNavigationGuard,
  createDesktopModalNavigationGuard,
  createDevtoolsNavigationGuard,
  createHeaderBackButtonNavigationGuard,
  createQueryNavigationGuard,
  createRegistrationNavigationGuard,
  createSeoMetaDataNavigationGuard,
  createSnackBarNavigationGuard,
  createThemeNavigationGuard,
} from './navigation-guards';
import { syncAuthStateToRoute } from './utils';

export default function createAppRouter(nuxtRouter?: Router): AppVueRouter {
  if (process.env.VUE_APP_RENDERING_CSR) {
    window.history.replaceState(null, '');
  }
  const i18nStore = useI18nStore();
  const {
    replacedUrlPrefix,
    currentUrlPrefix,
  } = i18nStore;

  const base = getBaseUrl(currentUrlPrefix) || process.env.BASE_URL || '/';

  const redirectUri = checkLanguagePrefix({
    replacedUrlPrefix,
    currentUrlPrefix,
  });

  let router;
  if (nuxtRouter) {
    // Patch nuxt router.
    const vueAppRouter = getAppVueRouter(nuxtRouter);
    router = Object.assign(nuxtRouter, vueAppRouter);
  } else {
    router = getAppVueRouter(createRouter({
      history: process.env.VUE_APP_PLATFORM_CORDOVA && process.env.NODE_ENV === 'production'
        ? createWebHashHistory(base)
        : createWebHistory(base),
      routes: [],
    }));
  }

  const { seoConfigs } = useRouterStore();
  router.setSeoConfigs(seoConfigs);
  // Not found route name
  router.addRoute({
    name: Symbol('not-found-routes'),
    path: '/:pathMatch(.*)*',
    redirect: {
      name: RouteName.ERROR_NOT_FOUND_404,
      replace: true,
    },
  });

  syncAuthStateToRoute(router);

  router.onError((error) => {
    if (!(error instanceof ModalOpenedRouteError)) {
      throw error;
    }

    return false;
  });

  createDevtoolsNavigationGuard(router);
  create301RedirectNavigationGuard(router, { redirectUri });

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    createCordovaNavigationGuard(router);
  }

  if (process.env.VUE_APP_RENDERING_CSR) {
    createSnackBarNavigationGuard(router);
  }
  createThemeNavigationGuard(router);
  createHeaderBackButtonNavigationGuard(router);

  createQueryNavigationGuard(router);
  createAccessRestrictionsNavigationGuard(router);

  if (process.env.VUE_APP_FEATURE_SIMPLIFIED_REGISTRATION_ENABLED || process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED) {
    createRegistrationNavigationGuard(router);
  }

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    createDesktopModalNavigationGuard(router);
  }

  if (!process.env.VUE_APP_PLATFORM_CORDOVA) {
    createSeoMetaDataNavigationGuard(router);
  }

  createBaseNavigationGuard(router);

  return router;
}
