import type { Theme } from '@leon-hub/api-sdk';

import { useThemeStore } from '@core/theme';

export default function initThemeAppInterface(): void {
  const themeStore = useThemeStore();

  window.mainApp = window.mainApp || {};
  window.mainApp.theme = {
    getTheme(): Theme {
      return themeStore.theme;
    },
    toggleTheme() {
      themeStore.toggleTheme();
    },
  };
}
