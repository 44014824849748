import type { DirectiveBinding } from 'vue';
import { nextTick } from 'vue';

interface ResizeOptions {
  childRef: HTMLElement | null; // eslint-disable-line @typescript-eslint/no-redundant-type-constituents
  parentRef: HTMLElement | null; // eslint-disable-line @typescript-eslint/no-redundant-type-constituents
  minSpace?: number;
  classOnOverflow: string;
}

const resizeObservers = new Map<HTMLElement, ResizeObserver>();

function setupResizeObserver (el: HTMLElement, options: ResizeOptions, adjustClasses: () => void) {
  const observer = new ResizeObserver(() => adjustClasses());

  if (options.childRef) observer.observe(options.childRef);
  if (options.parentRef) observer.observe(options.parentRef);

  resizeObservers.set(el, observer);

  adjustClasses();
}

function cleanupResizeObserver (el: HTMLElement) {
  const observer = resizeObservers.get(el);
  if (observer) {
    observer.disconnect();
    resizeObservers.delete(el);
  }
}

function createAdjustClasses (el: HTMLElement, options: ResizeOptions) {
  return () => {
    const {
      childRef: child,
      parentRef: parent,
      classOnOverflow,
      minSpace: minSpaceInput,
    } = options;

    if (!child || !parent) {
      return;
    }

    child.classList.remove(classOnOverflow);

    const parentWidth = parent.offsetWidth || 0;
    const childWidth = child.offsetWidth || 0;
    const minSpace = minSpaceInput || 10;

    if (childWidth > parentWidth - minSpace) {
      child.classList.add(classOnOverflow);
      el.dispatchEvent(new CustomEvent('auto-resized'));
    }
  }
}

function initializeObserver (el: HTMLElement, binding: DirectiveBinding<ResizeOptions>) {
  cleanupResizeObserver(el);

  void nextTick(() => {
    const options = binding.value || {};
    const adjustClasses = createAdjustClasses(el, options);

    setupResizeObserver(el, options, adjustClasses);
  });
}

const autoResize = {
  mounted(el: HTMLElement, binding: DirectiveBinding<ResizeOptions>) {
    initializeObserver(el, binding);
  },

  updated(el: HTMLElement, binding: DirectiveBinding<ResizeOptions>) {
    initializeObserver(el, binding);
  },

  unmounted(el: HTMLElement) {
    cleanupResizeObserver(el);
  },
};

export default autoResize;
