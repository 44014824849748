import { CasinoRouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from '@core/router';
import { AppModule } from '@core/modularization';

export class CasinoBetgamesModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    const navigation = process.env.VUE_APP_LAYOUT_PHONE
      ? () => import('../navigation/views/CasinoNavigationRouteComponent/CasinoNavigationRouteComponent.vue')
      : undefined;

    router.addDefaultRoute({
      name: CasinoRouteName.CASINO_BETGAMES,
      path: '/betgames',
      prefetch: () => import('./prefetch/CasinoBetgamesPagePrefetch'),

      component: () => import('./pages/CasinoBetgamesRoutePage/CasinoBetgamesRoutePage.vue'),
      navigation,
    });
  }
}
