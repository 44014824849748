<script setup lang="ts">
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { SBadge } from '@components/badge';
import {
  ButtonHeight,
  ButtonKind,
  LButton,
} from '@components/buttons';
import { VIcon } from '@components/v-icon';

import { useCustomerNotificationsUnreadCounter } from 'web/src/modules/customer-notifications/composables';

const { unreadCounter } = useCustomerNotificationsUnreadCounter();
</script>

<template>
  <router-link v-auto-id="'HeaderNotificationsButton'"
    :class="$style['header-notification-button']"
    :to="{ name: RouteName.CUSTOMER_NOTIFICATIONS }"
  >
    <template v-if="null">
      <div
        :class="$style['header-notification-button__icon']"
      >
        <VIcon
          :name="IconName.SLOTT_BELL"
          :size="null ? IconSize.SIZE_20 : IconSize.SIZE_24"
        />
      </div>
      <SBadge
        v-if="unreadCounter"
        is-counter
        type="secondary"
        size="small"
        :label="unreadCounter"
        :class="$style['header-notification-button__badge']"
      />
    </template>
    <template
      v-else
    >
      <LButton
        :icon-name="IconName.BELL"
        :icon-size="IconSize.SIZE_20"
        rounded
        :kind="'1' ? ButtonKind.HEADER_DARK_VERSION : ButtonKind.HEADER"
        :height="ButtonHeight.SMALL"
      />
      <div
        v-if="unreadCounter"
        :class="$style['header-notification-button__indicator']"
      />
    </template>
  </router-link>
</template>

<style module lang="scss">
@include for-layout using($isDesktop) {
  .header-notification-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: if($isDesktop, 40px, 36px);
      height: if($isDesktop, 40px, 36px);
      color: var(--ColorContent1a);
      background-color: var(--ColorBackground1);
      border-radius: 64px;

      &:hover {
        color: var(--ColorContent1);
      }
    }

    &__badge {
      position: absolute;
      top: -4px;
      right: -4px;
    }

    &__indicator {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 5px;
      height: 5px;
      background-color: var(--AlertDefault);
      border-radius: 50%;

      &--danger {
        background-color: var(--ErrorDefault);
      }
    }
  }
}
</style>
