import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import { useSiteConfigStore } from '@core/site-config';

import { useLayoutDesktopInfo } from 'web/src/modules/core/composables';

import { useLayoutScroll } from '../../composables';

export interface UseLayoutDesktopDefaultComposable {
  content: Ref<HTMLElement | undefined>;
  hasFixedBar: Ref<boolean>;
  hasLeftSideBar: Ref<boolean>;
  hasRightSideBar: Ref<boolean>;
  isFullWidth: Ref<boolean>;
  isStoryBanner: Ref<boolean>;
  isEgsLobby: Ref<boolean>;
  isFooterVisible: Ref<boolean>;
  isBannerVisible: Ref<boolean>;
}

export default function useLayoutDesktopDefault(): UseLayoutDesktopDefaultComposable {
  const currentRoute = useRoute();

  const content = ref<HTMLElement>();
  const isEgsStoryBannerEnabled = toRef(useSiteConfigStore(), 'isEgsStoryBannerEnabled');

  const {
    hasLeftSideBar,
    hasRightSideBar,
    isFullWidth,
  } = useLayoutDesktopInfo();

  const hasFixedBar = computed<boolean>(() => !!currentRoute.meta.hasFixedBar);
  const isFooterVisible = computed(() => !currentRoute.meta.isFooterHidden);
  const isEgsLobby = computed(() => !!currentRoute.meta.isEgsLobbyPage);
  const isStoryBanner = computed(() => isEgsStoryBannerEnabled.value && isEgsLobby.value);
  const isBannerVisible = computed(() => !currentRoute.meta.isBannerHidden);

  const { restoreScrollPosition } = useLayoutScroll(content);
  watch(() => currentRoute.fullPath, async () => {
    await nextTick();
    if (!currentRoute.meta.doSaveScrollPosition) {
      await restoreScrollPosition();
    }
  });

  return {
    content,
    hasFixedBar,
    hasLeftSideBar,
    hasRightSideBar,
    isFullWidth,
    isStoryBanner,
    isEgsLobby,
    isFooterVisible,
    isBannerVisible,
  };
}
