import { getLexGuardSession } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { GetLexisNexisSessionId } from '../types';
import { getLexisNexisSessionStorage } from '../utils/getLexisNexisSessionStorage';

export const getLexisNexisSessionId: GetLexisNexisSessionId = async (options): Promise<string> => {
  const { cache = true, login } = options;
  const storage = getLexisNexisSessionStorage(login);
  if (cache) {
    const session = await storage.getSessionId();
    if (session)
      return session;
  }

  const data = await getLexGuardSession(useGraphqlClient(), (node) => node.queries.system.getLexGuardSession);
  await storage.setSessionId(data.id, { expires: data.expiresAt });
  return data.id;
};
