import createDebug from 'debug';
import { createApp, h } from 'vue';

import { AppModuleApiProvider } from '@leon-hub/app-module';
import { getCordovaAppConfig } from '@leon-hub/cordova';
import { logger } from '@leon-hub/logging';

import { useErrorsConverter } from '@core/errors';
import { useFontsStore } from '@core/fonts';

import configureVueApp from 'web/src/core/app/vue/configureVueApp';
import { configureVueAppEssentials } from 'web/src/core/app/vue/configureVueAppEssentials';
import { useActivityReporter } from 'web/src/modules/activity-reporter/useActivityReporter';
import { onAppMounted } from 'web/src/modules/auth/utils/onAppMounted';
import { bootstrapIcons } from 'web/src/modules/core/apps/main/bootstrapIcons';
import { consumePrefetch } from 'web/src/modules/core/apps/main/prefetch/consumePrefetch';
import { createPrefetchProducer } from 'web/src/modules/core/apps/main/prefetch/createPrefetchProducer';
import { setupEmitter } from 'web/src/modules/core/apps/main/setupEmitter';
import MainApp from 'web/src/modules/core/apps/MainApp/MainApp.vue';
import { getAppModulePlugin } from 'web/src/modules/core/plugins';
import { createAppRouter } from 'web/src/modules/core/services/router';
import { createSimpleApp } from 'web/src/modules/core/utils';
import { getErrorAppComponentByError } from 'web/src/modules/errors/utils';
import initPublicAppInterface from 'web/src/plugins/AppPlugins/initPublicAppInterface';

import {
  forcePhoneLayout,
  getAppModules,
  initLogger,
  initStores,
  setupAppBusEvents,
  setupRouterLoadedEvents,
  startSentry,
  tryCordovaLogin,
} from './utils';

import 'web/src/assets/sass/mainApp.scss';

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function startApp(prefetchProducer = createPrefetchProducer(), app = createApp(MainApp)): Promise<void> {
  try {
    configureVueAppEssentials(app);
    void useFontsStore().loadFonts();
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      await tryCordovaLogin();
    }

    await consumePrefetch(await prefetchProducer);
    setupEmitter();
    configureVueApp(app);
    initLogger();
    if (
      process.env.VUE_APP_PLATFORM_WEB
      && process.env.VUE_APP_LAYOUT_DESKTOP
      && !process.env.VUE_APP_PRERENDER
    ) {
      forcePhoneLayout().catch((err: unknown) => logger.error('Unable sync layout env', err));
    }

    initStores();
    const router = createAppRouter();
    app.use(getAppModulePlugin(), {
      router,
      modules: getAppModules(),
    });
    if (!process.env.VUE_APP_BUNDLER_NUXT) {
      app.use(router);
    }

    void startSentry(app, router);

    setupAppBusEvents(router);

    initPublicAppInterface(router);

    bootstrapIcons();

    AppModuleApiProvider.reset();
    void useActivityReporter().start();

    await setupRouterLoadedEvents(router);
    if (!process.env.VUE_APP_BUNDLER_NUXT) {
      const debug = createDebug('nuxt');
      debug('TODO: disable nuxt mocks');
      app.component('NuxtLayout', () => h('div'));
      app.component('NuxtPage', () => h('div'));
      app.mount('#app');
    }
    void onAppMounted();
  } catch (rawError) {
    if (process.env.VUE_APP_RENDERING_SSR)
      throw rawError;
    const error = useErrorsConverter().convertToBaseError(rawError);
    logger.error('Start App Error', error);
    if (process.env.VUE_APP_PRERENDER) {
      // eslint-disable-next-line no-console
      console.info('Error JSON', error.toJSON());
    }

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const { sentryProjectId, sentryPublicKey } = getCordovaAppConfig();
      if (sentryProjectId && sentryPublicKey) {
        const body: { message: string; tags: Record<string, string> } = {
          message: `Start App Error: ${JSON.stringify(error.toJSON())}`,
          tags: { initError: '1' },
        };

        fetch(`https://sentry.io/api/${sentryProjectId}/store/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Sentry-Auth': `Sentry sentry_version=7, sentry_key=${sentryPublicKey}`,
          },
          body: JSON.stringify(body),
        })

          .catch((e) => console.error('Sentry error:', e));
      }
    }
    try {
      // In case of main app was already mounted on #app element.
      // Sentry shows errors within "simple-app" mount process.
      app.unmount();
    } catch {}
    createSimpleApp(getErrorAppComponentByError(rawError));
    bootstrapIcons();

    throw rawError;
  }
}
